/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import MetaTags from 'react-meta-tags'
import PropTypes from 'prop-types'
// Strap
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Table,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
// Router
// Table
// Breadcrumb
import Breadcrumbs from '../../../Base/components/Common/Breadcrumb'

// Iotera
import { safeDeepGet } from 'iotera-base/utility/json'
import ReactExport from 'react-data-export'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn
import 'tippy.js/dist/tippy.css'
// Redux
import { connect } from 'react-redux'
import { detail_transaction } from '../../store/transaction-admin/actions'
// import { updateRefund, refunded } from '../../store/transaction/actions';
class DetailTransactions extends Component {
  constructor(props) {
    super(props)
    this.node = React.createRef()
    let orderId = null
    const path = this.props.match.path
    if (path.includes('/detail')) {
      orderId = safeDeepGet(this.props.match.params, 'id')
    }

    this.state = {
      role: '',
      orderId,
      products: [],
      modal: false,
    }
    this.handleValidDate = this.handleValidDate.bind(this)
    this.handleValidTime = this.handleValidTime.bind(this)
    this.handleValidRefundDate = this.handleValidRefundDate.bind(this)

    this.toggle = this.toggle.bind(this)
    this.showToast = this.showToast.bind(this)
  }

  handleValidTime = date => {
    const time1 = new Date(date).toLocaleTimeString('en-GB')
    return time1
  };
  handleValidRefundDate = date => {
    const date1 = new Date(date).toLocaleDateString('en-GB')
    return date1
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }


  timeConvert(n) {
    var num = n
    var day = num / 60 / 24
    var rday = Math.floor(day)
    var hours = (day - rday) * 24
    var rhours = Math.round(hours)
    return rday + ' Days(s) and ' + rhours + ' hours(s) '
  }
  // handleValidDate = date => {
  //   const date1 = date.toDate().toLocaleDateString('en-GB');
  //   const time1 = date.toDate().toLocaleTimeString('en-GB');
  //   return date1 + ' ' + time1;
  // };
  showToast() {
    var toastType = 'info'
    var message = 'Refund sedang di Proses'
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '10000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    }

    if (toastType === 'info') toastr.info(message)
    else if (toastType === 'warning') toastr.warning(message)
    else if (toastType === 'error') toastr.error(message)
    else toastr.success(message)
  }
  componentDidMount() {
    const order_id = this.state.orderId
    this.props.DetailTransaction(order_id)
  }
  handleValidDate = date => {
    // const date1 = date.toDate().toLocaleDateString('en-GB');
    // const time1 = date.toDate().toLocaleTimeString('en-GB');
    const date1 = new Date(date).toLocaleDateString('en-GB')
    const time1 = new Date(date).toLocaleTimeString('en-GB')

    return date1 + ' ' + time1
  };
  format_report = list => {
    const dataSet = []
    let trx_data = {}
    for (const el in list) {
      trx_data = {
        business_point: safeDeepGet(list[el], 'business_point', '-'),
        total_data: safeDeepGet(list[el], 'total_data', 0),
        percentage_revenue: safeDeepGet(list[el], 'percentage_revenue', 0),
        nett_transaction: safeDeepGet(list[el], 'nett_transaction', 0),
        iotera_profit: safeDeepGet(list[el], 'iotera_profit', 0),
        bruto_transaction: safeDeepGet(list[el], 'bruto_transaction', 0),
        threshold_transaction: safeDeepGet(
          list[el],
          'threshold_transaction',
          0
        ),
        amount: safeDeepGet(list[el], 'amount', 0),
      }
      dataSet.push(trx_data)
    }
    return dataSet
  };
  render() {
    // TODO Loading
    const arr_list = safeDeepGet(this.props, ['result', 'body'], [])
    const list = arr_list[0]
    const notes = safeDeepGet(list, ['detail', 'notes'], '')
    const businessPoint = safeDeepGet(list, 'bp', [])
    let report_filename = 'DetailTrx' + this.state.orderId
    const report_list = this.format_report(businessPoint)
    const reference = safeDeepGet(list, ['detail', 'reference_no'], '')
    const split = reference.split('/')
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Detail Transaction | Admin</title>
          </MetaTags>

          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Transaction" breadcrumbItem="Detail" />
            <Row>
              <Col lg="12">
                <Card>
                  {arr_list.length !== 0 ? (
                    <CardBody>
                      <Row>
                        <Col lg="10">
                          <div className="table-responsive mb-2">
                            <Table className="table align-middle table-nowrap">
                              <thead>
                                <tr>
                                  <th scope="col">Order Id</th>
                                  <th scope="row">
                                    <div>
                                      <h5 className="text-truncate font-size-14">
                                        {list.detail.order_id}
                                      </h5>
                                    </div>
                                  </th>
                                </tr>

                                <tr>
                                  <th scope="col">Date</th>
                                  <td>
                                    <div>
                                      <span>
                                        {this.handleValidDate(
                                          list.time.timestamp
                                        )}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="col">Total</th>
                                  <td>
                                    <div>
                                      <span>
                                        {Number(
                                          safeDeepGet(
                                            list,
                                            ['payment', 'amount'],
                                            '0'
                                          )
                                        )
                                          .toFixed(2)
                                          .replace(
                                            /(\d)(?=(\d\d\d)+(?!\d))/g,
                                            '$1,'
                                          )}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="col">Reference</th>
                                  <td>
                                    <div>
                                      <span>{split[0]}</span>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="col">Period</th>
                                  <td>
                                    <div>
                                      <span>{split[1]}</span>
                                    </div>
                                  </td>
                                </tr>
                              </thead>
                            </Table>
                          </div>
                        </Col>
                        <Col
                          sm="2"
                          className="d-none d-lg-block"
                          align="center"
                        >
                          <div className="clearfix mt-4 mt-lg-0">
                            <ExcelFile
                              filename={report_filename}
                              element={
                                <button
                                  type="button"
                                  className="btn btn-primary w-sm"
                                  disabled={list.length == 0 ? true : false}
                                >
                                  <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                                  Download Excel
                                </button>
                              }
                            >
                              <ExcelSheet
                                data={report_list}
                                name="Transaction List"
                              >
                                <ExcelColumn
                                  label="Business Point"
                                  value="business_point"
                                />
                                <ExcelColumn
                                  label="Total Transaction"
                                  value="total_data"
                                />
                                <ExcelColumn
                                  label="Subscription Fee"
                                  value="amount"
                                />
                                <ExcelColumn
                                  label="Share Revenue"
                                  value="percentage_revenue"
                                />
                                <ExcelColumn
                                  label="Bruto"
                                  value="bruto_transaction"
                                />
                                <ExcelColumn
                                  label="Threshold Transaction"
                                  value="threshold_transaction"
                                />
                                <ExcelColumn
                                  label="Iotera Profit"
                                  value="iotera_profit"
                                />
                                <ExcelColumn
                                  label="Nett Transaction"
                                  value="nett_transaction"
                                />
                              </ExcelSheet>
                            </ExcelFile>
                          </div>
                        </Col>
                      </Row>

                      <Card>
                        <CardTitle></CardTitle>
                        <CardBody>
                          {notes == 'share_revenue' ? (
                            <div className="table-responsive mb-3">
                              <Table className="table align-middle table-nowrap">
                                <thead>
                                  <tr>
                                    <th scope="col">Business Point</th>
                                    <th scope="col">Total transaction</th>
                                    <th scope="col">Share Revenue</th>
                                    <th scope="col">Threshold Transaction</th>
                                    <th scope="col">Bruto</th>
                                    <th scope="col">Net Transaction</th>
                                    <th scope="col">Iotera Profit</th>
                                  </tr>
                                </thead>
                                {businessPoint.map((point, index) => (
                                  <tbody key={index}>
                                    <tr
                                      className={
                                        safeDeepGet(
                                          businessPoint[index],
                                          'bruto_transaction',
                                          0
                                        ) <
                                        safeDeepGet(
                                          businessPoint[index],
                                          'threshold_transaction',
                                          0
                                        )
                                          ? 'table-danger'
                                          : null
                                      }
                                    >
                                      <th scope="row">
                                        <div>
                                          <h5 className="text-truncate text-right font-size-14 text-wrap">
                                            {safeDeepGet(
                                              businessPoint[index],
                                              'business_point',
                                              ''
                                            )}
                                          </h5>
                                        </div>
                                      </th>
                                      <td>
                                        <div>
                                          <h5
                                            className="text-truncate text-right font-size-14"
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'flex-end',
                                            }}
                                          >
                                            {safeDeepGet(
                                              businessPoint[index],
                                              'total_data',
                                              0
                                            )}
                                          </h5>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <h5
                                            className="text-truncate text-right font-size-14"
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'flex-end',
                                            }}
                                          >
                                            {safeDeepGet(
                                              businessPoint[index],
                                              'percentage_revenue',
                                              0
                                            ) *
                                              100 +
                                              '%'}
                                          </h5>
                                        </div>
                                      </td>

                                      <td>
                                        <div>
                                          <h5
                                            className="text-truncate text-right font-size-14"
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'flex-end',
                                            }}
                                          >
                                            {Number(
                                              safeDeepGet(
                                                businessPoint[index],
                                                'threshold_transaction',
                                                0
                                              )
                                            )
                                              .toFixed(2)
                                              .replace(
                                                /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                '$1,'
                                              )}
                                          </h5>
                                        </div>
                                      </td>
                                      <th scope="row">
                                        <div>
                                          <h5
                                            className="text-truncate text-right font-size-14"
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'flex-end',
                                            }}
                                          >
                                            {Number(
                                              safeDeepGet(
                                                businessPoint[index],
                                                'bruto_transaction',
                                                0
                                              )
                                            )
                                              .toFixed(2)
                                              .replace(
                                                /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                '$1,'
                                              )}
                                          </h5>
                                        </div>
                                      </th>

                                      <td>
                                        <div>
                                          <h5
                                            className="text-truncate text-right font-size-14"
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'flex-end',
                                            }}
                                          >
                                            {Number(
                                              safeDeepGet(
                                                businessPoint[index],
                                                'nett_transaction',
                                                0
                                              )
                                            )
                                              .toFixed(2)
                                              .replace(
                                                /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                '$1,'
                                              )}
                                          </h5>
                                        </div>
                                      </td>
                                      <th scope="row">
                                        <div>
                                          <h5
                                            className="text-truncate text-right font-size-14"
                                            style={{
                                              color: 'green',
                                              display: 'flex',
                                              justifyContent: 'flex-end',
                                            }}
                                          >
                                            +
                                            {Number(
                                              safeDeepGet(
                                                businessPoint[index],
                                                'iotera_profit',
                                                0
                                              )
                                            )
                                              .toFixed(2)
                                              .replace(
                                                /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                '$1,'
                                              )}
                                          </h5>
                                        </div>
                                      </th>
                                    </tr>
                                  </tbody>
                                ))}
                              </Table>
                            </div>
                          ) : (
                            <Table className="table align-middle table-nowrap">
                              <thead>
                                <tr>
                                  <th scope="col">Business Point</th>
                                  <th scope="col">Fee</th>
                                </tr>
                              </thead>
                              {businessPoint.map((point, index) => (
                                <tbody key={index}>
                                  <tr
                                    className={
                                      safeDeepGet(
                                        businessPoint[index],
                                        'nett_transaction',
                                        0
                                      ) <
                                      safeDeepGet(
                                        businessPoint[index],
                                        'threshold_transaction',
                                        0
                                      )
                                        ? 'table-danger'
                                        : null
                                    }
                                  >
                                    <th scope="row">
                                      <div>
                                        <h5 className="text-truncate font-size-14 text-wrap">
                                          {safeDeepGet(
                                            businessPoint[index],
                                            'business_point',
                                            ''
                                          )}
                                        </h5>
                                      </div>
                                    </th>
                                    <th scope="row">
                                      <div>
                                        <h5 className="text-truncate font-size-14" >
                                          {Number(
                                            safeDeepGet(
                                              businessPoint[index],
                                              'amount',
                                              safeDeepGet(
                                                businessPoint[index],
                                                'price',
                                                0
                                              )
                                            )
                                          )
                                            .toFixed(2)
                                            .replace(
                                              /(\d)(?=(\d\d\d)+(?!\d))/g,
                                              '$1,'
                                            )}
                                        </h5>
                                      </div>
                                    </th>
                                  </tr>
                                </tbody>
                              ))}
                            </Table>
                          )}
                        </CardBody>
                      </Card>
                    </CardBody>
                  ) : null}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['transaction_admin', 'transaction_detail'], {}),
  loading: safeDeepGet(state, ['transaction_admin', 'loading'], true),
  refundResult: safeDeepGet(state, ['transaction_admin', 'refunded'], {}),
  test: state,
})
DetailTransactions.propTypes = {
  onRefund: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  DetailTransaction: payload => dispatch(detail_transaction(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailTransactions)
