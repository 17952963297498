/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable react/jsx-key */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import 'flatpickr/dist/themes/material_blue.css';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Link } from 'react-router-dom';

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Modal,
  Label,
  CardTitle,
  ModalHeader,
  Table,
  FormGroup,
  Collapse,
  ModalBody,
  Form,
  Input,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { APPLICATION_ID, NAME_PROJECT, buildTitle } from 'Apps';
//Import Breadcrumb
// Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';

//CSV File
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
// Redux
import { connect } from 'react-redux';
import {
  updatePlanogramMCPro,
  readPlanogramMCPro,
} from '../../store/business-point/actions';
import { CircularProgress } from '@material-ui/core';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import FailedAnimation from 'Base/assets/FailedAnimation';
import SuccessAnimation from 'Base/assets/SuccessAnimation';
import LoadingAnimation from 'Base/assets/LoadingAnimation';
class PlanogramList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    // let deviceId = null;
    const { data, type } = props;
    const deviceId = data;
    const deviceType = type;
    this.state = {
      col2: false,
      modalDetail: false,
      deviceId: deviceId,
      deviceType,
      bpfilterlist: [],
      businessPoints: 'all',
      transactionStatus: 'all',
      dispenseStatus: 'all',
      file: '',
      array: [],
      headerTable: '',
      valuesArray: '',
      qris_price: [],
      cash_price: [],
      isLoading: true,
      serverless_code: 20,
      modalPlanogram: false,
      planogram_history: [],

    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleImportClick = this.handleImportClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.toggleDetail = this.toggleDetail.bind(this);
    this.handleAddRowCash = this.handleAddRowCash.bind(this);
    this.handleAddRowQris = this.handleAddRowQris.bind(this);
    this.handleRemoveRowCash = this.handleRemoveRowCash.bind(this);
    this.handleRemoveRowQris = this.handleRemoveRowQris.bind(this);
    this.handleInputChangeCash = this.handleInputChangeCash.bind(this);
    this.handleInputChangeQris = this.handleInputChangeQris.bind(this);
    this.togglePlanogram = this.togglePlanogram.bind(this);
  }
  togglePlanogram() {
    this.setState(prevState => ({
      modalPlanogram: !prevState.modalPlanogram,
    }));
  }
  t_col2() {
    this.setState({
      col2: !this.state.col2,
    });
  }
  toggleDetail() {
    this.setState(prevState => ({
      modalDetail: !prevState.modalDetail,
    }));
  }
  handleImportClick = row => {
    this.toggleDetail();
  };
  handleAddRowCash = () => {
    const item = {
      price: 0,
      duration: 0,
    };
    this.setState({
      cash_price: [...this.state.cash_price, item],
    });
  };
  handleAddRowQris = () => {
    const item = {
      price: 0,
      duration: 0,
    };
    this.setState({
      qris_price: [...this.state.qris_price, item],
    });
  };
  handleRemoveRowCash = (e, idx) => {
    if (this.state.cash_price.length > 1) {
      console.log(idx);
      this.state.cash_price.splice(idx, 1);
      this.setState({ cash_price: this.state.cash_price });
    }
  };
  handleRemoveRowQris = (e, idx) => {
    if (this.state.qris_price.length > 1) {
      this.state.cash_price.splice(idx, 1);
      this.setState({ cash_price: this.state.cash_price });
    }
  };
  handleInputChangeCash = (e, idx) => {
    const { name, value } = e.target;
    const { cash_price } = this.state;

    const updatedCashPrice = [...cash_price];
    updatedCashPrice[idx][name] = value;

    this.setState({ cash_price: updatedCashPrice });
  };
  handleInputChangeQris = (e, idx) => {
    const { name, value } = e.target;
    const { qris_price } = this.state;

    const updatedQrisPrice = [...qris_price];
    updatedQrisPrice[idx][name] = value;

    this.setState({ qris_price: updatedQrisPrice });
  };
  handleValidDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };
  componentDidMount() {
    const { deviceId } = this.state;
    this.props.ReadPlanogram(deviceId);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };
  handleOnChange = e => {
    const file = e.target.files[0];
    if (file.size > 5000) {
      this.setState({ file });
    } else {
      this.setState({ file });
    }
    // let valuesArray =[]
    Papa.parse(e.target.files[0], {
      header: true,
      complete: function (results) {
        for (const i in results) {
          const valuesArray = results['data'];
          this.setState({ valuesArray });
        }
        const rowsArray = [];
        // Iterating data to get column name and their values
        results.data.map(d => {
          rowsArray.push(Object.keys(d));
        });
        this.setState({ rowsArray });
      }.bind(this),
    });
  };

  handleSubmit(event, values) {
    this.togglePlanogram()
    const payload = {
      device_id: this.state.deviceId,
      device_type: this.state.deviceType,
      qris_price: this.state.qris_price,
      cash_price: this.state.cash_price,
      debounce: Number(values.debounce),
      min_cash_denom: Number(values.min_cash_denom),
      pulse: Number(values.pulse),
      // ...values,
    };
    this.setState({ loading: true });
    this.props.InputPlanogram(payload);
  }
  showToastNoProduct() {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };
    toastr.error('Terdapat Produk yang tidak terdaftar');
  }
  showToastFailed(code, message) {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };
    toastr.error('Gagal Update Planogram : ' + code + ' ' + message);
  }
  showToast() {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };
    toastr.success('Berhasil Update Planogram');
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      const message = safeDeepGet(
        this.props,
        ['createResult', 'message', 'text'],
        ''
      );
      const serverless_text = safeDeepGet(
        this.props,
        ['createResult', 'message', 'message'],
        message
      );
      const serverless_code = safeDeepGet(
        this.props,
        ['createResult', 'message', 'result'],
        Result.UNKNOWN_ERROR
      );
      const ts = safeDeepGet(
        this.props,
        ['result', 'ts'],
        new Date().getTime()
      );
      const planogram_history = safeDeepGet(
        this.props,
        ['result', 'planogram_history'],
        []
      );
      const name = safeDeepGet(this.props, ['result', 'name'], this.state.name);
      const mssage_serveless =
        'Message: ' + serverless_text + ' error code ' + serverless_code;
        this.setState({
          loading: false,
          serverless_code,
          ts,
          planogram_history,
          name,
        });
      if (code != 999) {
        if (code === Result.SUCCESS || code == Result.HTTP.OK) {
          // this.showToast();
          this.componentDidMount();
          // setTimeout(() => window.location.reload(), 2000);
        } else if (code === '-4') {
          // this.showToastNoProduct(code, mssage_serveless);
        } else {
          // this.showToastFailed(code, mssage_serveless);
        }
      }
    }
    if (this.props.readPlanogramResult !== prevProps.readPlanogramResult) {
      const code = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        const planogramMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'planogram'],
          {}
        );
        const history = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'planogram'],
          []
        );
        const name = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'name'],
          []
        );
        const timeStamp = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'time_stamp'],
          0
        );
        const user = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'user'],
          []
        );

        const cash_price_duration = safeDeepGet(
          planogramMap,
          'cash_price_duration',
          {}
        );
        const qris_price_duration = safeDeepGet(
          planogramMap,
          'qris_price_duration',
          {}
        );

        const cash_price = [];
        const qris_price = [];
        for (const i in cash_price_duration) {
          const duration = safeDeepGet(cash_price_duration, i, 0);
          cash_price.push({ price: i, duration });
        }
        for (const i in qris_price_duration) {
          const duration = safeDeepGet(qris_price_duration, i, 0);
          qris_price.push({ price: i, duration });
        }

        if (qris_price.length < 1) {
          qris_price.push({ price: 0, duration: 0 });
        }
        if (cash_price.length < 1) {
          cash_price.push({ price: 0, duration: 0 });
        }
        this.setState({
          history: history,
          name: name,
          timeStamp: timeStamp,
          user: user,
          planogram: planogramMap,
          cash_price: cash_price,
          qris_price: qris_price,
          isLoading: false,
        });
      }
    }
  }
  handledDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };
  render() {
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ['readPlanogramResult', 'code'],
      Result.UNKNOWN_ERROR
    );
    const defaultSorted = [
      {
        dataField: 'order', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc', // desc or asc
      },
    ];
    const formValues = safeDeepGet(
      this.props,
      ['readPlanogramResult', 'body', 'planogram'],
      []
    );

    const nameBP = safeDeepGet(
      this.props,
      ['readPlanogramResult', 'body', 'name'],
      ''
    );
    const { history, name, timeStamp, user, loading, isLoading, ts, planogram_history } = this.state;
    const filename = name + '_' + '_' + this.handledDate(timeStamp);
    const pulse = safeDeepGet(this.state, ['planogram', 'pulse_factor'], 1000);
    const debounce = safeDeepGet(this.state, ['planogram', 'debounce_wait'], 0);
    const min_cash_denom = safeDeepGet(
      this.state,
      ['planogram', 'min_cash_denom'],
      0
    );
    const price = safeDeepGet(this.state, ['planogram', 'qris_price'], 0);
    const { cash_price, qris_price, serverless_code } = this.state;
    const duration = safeDeepGet(this.state, ['planogram', 'qris_duration'], 0);
    const drole = localStorage.getItem('accessNav');
    const jsonRole = JSON.parse(drole);
    const guest = safeDeepGet(jsonRole, ['Business Point', 'guest'], 'off');
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Planogram')}</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <AvForm onValidSubmit={this.handleSubmit}>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      {isLoading ? (
                        <div className="text-center">
                          <CircularProgress className="text-center"></CircularProgress>
                        </div>
                      ) : (
                        <Row>
                          <Col
                            sm={6}
                            // style={{background: 'red'}}
                          >
                            <FormGroup className="mb-3">
                              <Label>Cash Price</Label>
                              <table style={{ width: '90%' }}>
                                <tbody>
                                  {this.state.cash_price.map((item, idx) => (
                                    <tr id={'addr' + idx} key={idx}>
                                      <td>
                                        <Form
                                          className="repeater mt-3"
                                          encType="multipart/form-data"
                                        >
                                          <div data-repeater-list="group-a">
                                            <Row data-repeater-item>
                                              <Col sm="5" className="mb-3">
                                                <Label htmlFor="name">
                                                  Price
                                                </Label>
                                                <Input
                                                  type="number"
                                                  id="price"
                                                  name="price"
                                                  defaultValue={item.price}
                                                  placeholder="Enter Your Price"
                                                  onChange={e =>
                                                    this.handleInputChangeCash(
                                                      e,
                                                      idx
                                                    )
                                                  }
                                                />
                                              </Col>

                                              <Col sm="5" className="mb-3">
                                                <Label htmlFor="number">
                                                  Duration
                                                </Label>
                                                <Input
                                                  type="number"
                                                  id="duration"
                                                  name="duration"
                                                  defaultValue={item.duration}
                                                  placeholder="Enter Your Duration"
                                                  onChange={e =>
                                                    this.handleInputChangeCash(
                                                      e,
                                                      idx
                                                    )
                                                  }
                                                />
                                              </Col>
                                              <Col
                                                sm="2"
                                                className="form-group align-self-center"
                                              >
                                                {cash_price.length > 1 ? (
                                                  <Button
                                                    onClick={e =>
                                                      this.handleRemoveRowCash(
                                                        e,
                                                        idx
                                                      )
                                                    }
                                                    color="primary"
                                                    className="mt-3"
                                                    style={{ width: '100%' }}
                                                  >
                                                    Delete
                                                  </Button>
                                                ) : (
                                                  <></>
                                                )}
                                              </Col>
                                            </Row>
                                          </div>
                                        </Form>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <Button
                                onClick={this.handleAddRowCash}
                                color="success"
                                className="mt-3 mt-lg-0"
                              >
                                Add Cash Price{' '}
                              </Button>{' '}
                              {/* <AvField
                              value={price}
                              name="price"
                              label="Harga Per Sesi (Rp)"
                              placeholder="Harga Per sesi"
                              type="number"
                              errorMessage="Masukkan Harga Dengan Benar"
                              className="form-control"
                              validate={{
                                required: { value: true },
                              }}
                            /> */}
                            </FormGroup>
                          </Col>
                          <Col
                            sm={6}
                            // style={{background: 'black'}}
                          >
                            <FormGroup className="mb-3">
                              <Label>QRIS Price</Label>
                              <table style={{ width: '90%' }}>
                                <tbody>
                                  {this.state.qris_price.map((item, idx) => (
                                    <tr id={'addr' + idx} key={idx}>
                                      <td>
                                        <Form
                                          className="repeater mt-3"
                                          encType="multipart/form-data"
                                        >
                                          <div data-repeater-list="group-a">
                                            <Row data-repeater-item>
                                              <Col sm="5" className="mb-3">
                                                <Label htmlFor="name">
                                                  Price
                                                </Label>
                                                <Input
                                                  type="number"
                                                  id="price"
                                                  name="price"
                                                  defaultValue={item.price}
                                                  placeholder="Enter Your Price"
                                                  onChange={e =>
                                                    this.handleInputChangeQris(
                                                      e,
                                                      idx
                                                    )
                                                  }
                                                />
                                              </Col>

                                              <Col sm="5" className="mb-3">
                                                <Label htmlFor="number">
                                                  Duration
                                                </Label>
                                                <Input
                                                  type="number"
                                                  id="duration"
                                                  name="duration"
                                                  defaultValue={item.duration}
                                                  placeholder="Enter Your Duration"
                                                  onChange={e =>
                                                    this.handleInputChangeQris(
                                                      e,
                                                      idx
                                                    )
                                                  }
                                                />
                                              </Col>
                                              <Col
                                                sm="2"
                                                className="form-group align-self-center"
                                              >
                                                {qris_price.length > 1 ? (
                                                  <Button
                                                    onClick={e =>
                                                      this.handleRemoveRowQris(
                                                        e,
                                                        idx
                                                      )
                                                    }
                                                    color="primary"
                                                    className="mt-3"
                                                    style={{ width: '100%' }}
                                                  >
                                                    Delete
                                                  </Button>
                                                ) : (
                                                  <></>
                                                )}
                                              </Col>
                                            </Row>
                                          </div>
                                        </Form>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <Button
                                onClick={this.handleAddRowQris}
                                color="success"
                                className="mt-3 mt-lg-0"
                              >
                                Add QRIS Price{' '}
                              </Button>{' '}
                              {/* <AvField
                              value={duration}
                              name="duration"
                              label="Durasi per Sesi (Detik)"
                              placeholder="Durasi per sesi"
                              type="number"
                              errorMessage="Masukkan Durasi Dengan Benar"
                              className="form-control"
                              validate={{
                                required: { value: true },
                              }}
                            /> */}
                            </FormGroup>
                          </Col>
                          <div
                            className="accordion accordion-flush"
                            id="accordion"
                          >
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button fw-medium"
                                  type="button"
                                  onClick={this.t_col2}
                                  style={{ cursor: 'pointer' }}
                                >
                                  Advance Setting
                                </button>
                              </h2>

                              <Collapse
                                isOpen={this.state.col2}
                                className="accordion-collapse"
                              >
                                <FormGroup className="mb-3 mx-3">
                                  {/* mdi-alert-circle-outline */}
                                  <Label>Pulse Factor</Label>{' '}
                                  <Tippy
                                    content={
                                      'Merupakan bilangan pembagi harga untuk menentukan berapa pulse yang akan dikirimkan untuk mengaktifkan timer. Contoh: jika harga yang dibayarkan adalah Rp 10000 dan pulse factor adalah 1000, maka akan dikirimkan 10 pulse (10000/1000). Jumlah pulse yang dikirimkan dapat diamati pada LCD timer pada kursi pijat'
                                    }
                                  >
                                    <Link className="text-info" to="#">
                                      <i
                                        className="mdi mdi-alert-circle-outline font-size-15"
                                        id="menutooltip"
                                        onClick={() => {}}
                                      ></i>
                                    </Link>
                                  </Tippy>
                                  <AvField
                                    value={pulse}
                                    name="pulse"
                                    placeholder="Pulse Factor"
                                    type="number"
                                    errorMessage="Masukkan Pulse dengan benar"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                  />
                                  {/* <Label>Debounce Wait *ms</Label>{' '}
                                <Tippy
                                  content={
                                    'Berapa mili detik jarak waktu minimal antara satu pulse dengan pulse selanjutnya. Jika jarak waktu suatu pulse dengan pulse sebelumnya kurang dari waktu debounce, pulse tersebut akan diabaikan karena dianggap noise. Default: 75 ms.'
                                  }
                                >
                                  <Link className="text-info" to="#">
                                    <i
                                      className="mdi mdi-alert-circle-outline font-size-15"
                                      id="menutooltip"
                                      onClick={() => {}}
                                    ></i>
                                  </Link>
                                </Tippy>
                                <AvField
                                  value={debounce ?? 75}
                                  name="debounce"
                                  placeholder="Debounce Wait"
                                  type="number"
                                  errorMessage="Masukkan waktu dengan benar"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                />
                                <Label>Minimum Cash Denom</Label>{' '}
                                <Tippy
                                  content={
                                    'Semua cash yang masuk akan dikoreksi supaya menjadi kelipatan Minimum cash denom terdekat'
                                  }
                                >
                                  <Link className="text-info" to="#">
                                    <i
                                      className="mdi mdi-alert-circle-outline font-size-15"
                                      id="menutooltip"
                                      onClick={() => {}}
                                    ></i>
                                  </Link>
                                </Tippy>
                                <AvField
                                  value={min_cash_denom ?? 75}
                                  name="min_cash_denom"
                                  placeholder="Minimom Cash Denom"
                                  type="number"
                                  errorMessage="Masukkan Nominal dengan benar"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                /> */}
                                </FormGroup>
                              </Collapse>
                            </div>
                          </div>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                  <div
                    style={{
                      position: 'fixed',
                      bottom: '20px',
                      right: '80px',
                      height: '50px',
                      zIndex: 999,
                    }}
                  >
                    <Row>
                      <Col
                        sm="6"
                        className="mt-3"
                        style={{
                          padding: '0px',
                          width: '200px',
                        }}
                        s
                      >
                        <p className="text-start" style={{ fontSize: '10px' }}>
                          Latest Planogram{' '}
                          {timeStamp == null
                            ? '-'
                            : this.handledDate(timeStamp)}
                        </p>
                      </Col>
                      <Col
                        sm="6"
                        style={{
                          padding: '0px',
                          width: '200px',
                        }}
                      >
                        {isLoading ? (
                          <div className="d-flex flex-wrap gap-2 float-end ">
                            <Link
                              to="/business-point/list"
                              color="secondary"
                              className="btn btn-secondary"
                              role="button"
                              // onClick={this.handleCancel}
                              disabled={loading}
                            >
                              Cancel
                            </Link>{' '}
                            {guest == 'off' ? (
                              <Button
                                onClick={this.componentDidMount}
                                color="primary"
                                disabled={loading}
                              >
                                Refresh
                              </Button>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <div className="d-flex flex-wrap gap-2 float-end ">
                            <Link
                              to="/business-point/list"
                              color="secondary"
                              className="btn btn-secondary"
                              role="button"
                              // onClick={this.handleCancel}
                              disabled={loading}
                            >
                              Cancel
                            </Link>{' '}
                            {guest == 'off' ? (
                              <Button
                                type="submit"
                                color="primary"
                                disabled={loading}
                              >
                                Simpan
                              </Button>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  {/* <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Button onClick={this.handleCancleClick} color="secondary">
                      Cancel
                    </Button>{' '}
                    <Button type="submit" color="primary" disabled={loading}>
                      Simpan
                    </Button>
                  </div> */}
                </Col>
              </AvForm>

              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h5">
                      Latest Changes Planogram by
                    </CardTitle>

                    <Row className="align-items-center">
                      <Col lg="9" className="align-self-center">
                        <div className="table-responsive">
                          <Table className="table align-middle table-nowrap table-hover mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Email</th>
                                <th scope="col">Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{user}</td>
                                <td>
                                  {timeStamp == null
                                    ? '-'
                                    : this.handledDate(timeStamp)}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>

                      <Col lg="3" className="d-none d-lg-block" align="center">
                        <div className="clearfix mt-4 mt-lg-0">
                          <ExcelFile
                            filename={filename}
                            element={
                              <button
                                type="button"
                                className="btn btn-primary w-sm"
                              >
                                <i className="mdi mdi-download d-block font-size-16"></i>
                                Download
                              </button>
                            }
                          >
                            <ExcelSheet data={history} name="Transaction List">
                              <ExcelColumn label="name" value="name" />
                              <ExcelColumn
                                label="pulse"
                                value="pulse"
                                numFmt="0"
                              />
                              <ExcelColumn label="duration" value="duration" />
                              <ExcelColumn
                                label="price"
                                value="price"
                                numFmt="0"
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              key="modal"
              size="lg"
              isOpen={this.state.modalPlanogram}
              className={this.props.className}
              toggle={this.togglePlanogram}
              fullscreen
            >
              {/* <ModalHeader toggle={this.togglePlanogram} tag="h4"></ModalHeader> */}
              <ModalBody>
                <div className=" text-left">
                  {serverless_code == Result.NETWORK_FAILURE ? (
                    <FailedAnimation />
                  ) : serverless_code == Result.TIMEOUT ? (
                    <FailedAnimation />
                  ) : serverless_code == -11 ? (
                    <FailedAnimation />
                  ) : serverless_code == Result.FAILED ? (
                    <FailedAnimation />
                  ) : serverless_code == Result.INPUT_FAILURE ? (
                    <FailedAnimation />
                  ) : serverless_code == 0 ? (
                    <SuccessAnimation />
                  ) : (
                    <LoadingAnimation />
                  )}
                  {serverless_code == 20 ? (
                    <h3 className="text-center">
                      Sedang memproses data, mohon tunggu sebentar...
                    </h3>
                  ) : (
                    <div>
                      <Row>
                        <Col sm="4"></Col>
                        <Col sm="4">
                          <Card className="border-solid border-black">
                            <div className="table-responsive mb-2">
                              <Table className="border-solid border-2 table align-middle table-wrap">
                                <thead>
                                  <tr>
                                    <th scope="col" className="text-start">
                                      <div>
                                        <h5 className="font-size-14">
                                          Planogram ID:
                                        </h5>
                                      </div>
                                    </th>
                                    <th scope="row" className="text-end">
                                      <div>
                                        <h5 className="font-size-14">{ts}</h5>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th scope="col" className="text-start">
                                      <div>
                                        <h5 className="font-size-14">
                                          Update By:
                                        </h5>
                                      </div>
                                    </th>
                                    <th scope="row" className="text-end">
                                      <div>
                                        <h5 className="font-size-14">
                                          {localStorage.getItem('email')}
                                        </h5>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th scope="col" className="text-start">
                                      <div>
                                        <h5 className="font-size-14">
                                          Update Time:
                                        </h5>
                                      </div>
                                    </th>
                                    <th scope="row" className="text-end">
                                      <div>
                                        <h5 className="font-size-14">
                                          {this.handleValidDate(ts)}
                                        </h5>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th scope="col" className="text-start">
                                      <div>
                                        <h5 className="font-size-14">
                                          Status:
                                        </h5>
                                      </div>
                                    </th>
                                    <th scope="row" className="text-end">
                                      <div>
                                        {serverless_code ===
                                        Result.NETWORK_FAILURE ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, mesin sedang offline.
                                          </h5>
                                        ) : serverless_code ===
                                          Result.TIMEOUT ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, mesin tidak memberikan
                                            respon.
                                          </h5>
                                        ) : serverless_code === -11 ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, mesin sedang sibuk
                                            melakukan transaksi.
                                          </h5>
                                        ) : serverless_code ===
                                          Result.FAILED ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, mesin tidak merespons
                                            atau sedang offline.
                                          </h5>
                                        ) : serverless_code ===
                                          Result.INPUT_FAILURE ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, format planogram
                                            salah. Silakan hubungi Tim Iotera.
                                          </h5>
                                        ) : serverless_code ===
                                          Result.SUCCESS ? (
                                          <h5 className="font-size-14">
                                            Proses Selesai, mesin telah di
                                            update dengan planogram baru.
                                          </h5>
                                        ) : (
                                          <h5 className="font-size-14">
                                            Sedang memproses data, mohon tunggu
                                            sebentar...
                                          </h5>
                                        )}
                                      </div>
                                    </th>
                                  </tr>
                                  {serverless_code === Result.SUCCESS ? (
                                    <tr>
                                      <th scope="col" className="text-start">
                                        <div>
                                          <h5 className="font-size-14">
                                            Planogram data
                                          </h5>
                                        </div>
                                      </th>
                                      <th scope="row" className="text-end">
                                        <div className="clearfix mt-4 mt-lg-0">
                                          <ExcelFile
                                            filename={
                                              name +
                                              '_' +
                                              this.handleValidDate(ts)
                                            }
                                            element={
                                              <button
                                                type="button"
                                                className="btn btn-primary w-sm"
                                              >
                                                <i className="mdi mdi-download d-block font-size-16"></i>
                                                Download
                                              </button>
                                            }
                                          >
                                            {APPLICATION_ID == '1000000211' ||
                                            APPLICATION_ID == '1000000226' ? (
                                              <ExcelSheet
                                                data={planogram_history}
                                                name="Planogram History"
                                              >
                                                <ExcelColumn
                                                  label="selection"
                                                  value="selection"
                                                  numFmt="0"
                                                />
                                                <ExcelColumn
                                                  label="sku"
                                                  value="sku"
                                                />
                                                <ExcelColumn
                                                  label="name"
                                                  value="name"
                                                />
                                                <ExcelColumn
                                                  label="price"
                                                  value="price"
                                                  numFmt="0"
                                                />
                                              </ExcelSheet>
                                            ) : APPLICATION_ID ==
                                              '1000000258' ? (
                                              <ExcelSheet
                                                data={planogram_history}
                                                name="Planogram History"
                                              >
                                                <ExcelColumn
                                                  label="pulse"
                                                  value="pulse"
                                                  numFmt="0"
                                                />
                                                <ExcelColumn
                                                  label="duration"
                                                  value="duration"
                                                />
                                                <ExcelColumn
                                                  label="price"
                                                  value="price"
                                                  numFmt="0"
                                                />
                                              </ExcelSheet>
                                            ) : (
                                              <ExcelSheet
                                                data={
                                                  planogram_history
                                                }
                                                name="Planogram History"
                                              >
                                                <ExcelColumn
                                                  label="Selection"
                                                  value="key"
                                                />
                                                <ExcelColumn
                                                  label="Product"
                                                  value="name"
                                                />
                                                <ExcelColumn
                                                  label="Product SKU"
                                                  value="product"
                                                />
                                                <ExcelColumn
                                                  label="Product Price"
                                                  value="price"
                                                  numFmt="0"
                                                />
                                                <ExcelColumn
                                                  label="Stock"
                                                  value="stock"
                                                />
                                                <ExcelColumn
                                                  label="Active"
                                                  value="active"
                                                />
                                                <ExcelColumn
                                                  label="Column"
                                                  value="column"
                                                />
                                              </ExcelSheet>
                                            )}
                                          </ExcelFile>
                                        </div>
                                      </th>
                                    </tr>
                                  ) : (
                                    <></>
                                  )}
                                </thead>
                              </Table>
                            </div>
                          </Card>
                        </Col>
                        <Col sm="4"></Col>
                      </Row>
                    </div>
                  )}
                </div>
                <div className="text-center mt-4">
                  {serverless_code == Result.SUCCESS ? (
                    <Link
                      to={'/business-point/history/planogram'}
                      style={{
                        color: 'white',
                      }}
                      className="mb-2 btn btn-primary"
                    >
                      Next
                    </Link>
                  ) : serverless_code == 20 ? (
                    <></>
                  ) : (
                    <Button color="primary" onClick={this.togglePlanogram}>
                      Close
                    </Button>
                  )}
                </div>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  createResult: safeDeepGet(
    state,
    ['businessPoint', 'updatePlanogramMCPro'],
    {}
  ),
  readPlanogramResult: safeDeepGet(
    state,
    ['businessPoint', 'readPlanogramMCPro'],
    {}
  ),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  ReadPlanogram: id => dispatch(readPlanogramMCPro(id)),
  InputPlanogram: payload => dispatch(updatePlanogramMCPro(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramList);
