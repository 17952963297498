/* eslint-disable no-case-declarations */
/* eslint-disable semi */
import {
  LOADING,
  RESULT,
  LIST,
  LIST_VM,
  SERI_VM,
  INIT_CREATE,
  CREATE,
  READ_INFO,
  UPDATE_INFO,
  UPDATE_CALIBRATION,
  SET_PLANOGRAM_CSV,
  DELETE,
  READ_PRODUCT,
  LIST_PRODUCT,
  INPUT_CSV,
  HISTORY,
  CLEAR_CASH,


  UPDATE_PLANOGRAM_MCPRO,
  UPDATE_PLANOGRAM_MILANO,
  UPDATE_PLANOGRAM_RETAIL,
  UPDATE_PLANOGRAM_LEVENDING,
  UPDATE_PLANOGRAM,
  UPDATE_PLANOGRAM_RETAIL_OLD,
  UPDATE_PLANOGRAM_FRANKE,
  READ_PLANOGRAM_RETAIL,
  READ_PLANOGRAM_LEVENDING,
  READ_PLANOGRAM_RETAIL_OLD,
  READ_PLANOGRAM_MILANO,
  READ_PLANOGRAM,
  READ_PLANOGRAM_FRANKE,
  READ_PLANOGRAM_MCPRO,
  STOCK_BUSINESS_POINT,
  UPDATE_SHARE_REVENUE,
  GET_SHARE_REVENUE,  
  READ_PLANOGRAM_WATER_DISPENSER,
  UPDATE_PLANOGRAM_WATER_DISPENSER,
  RESET_FLOWMETER,
  READ_PLANOGRAM_ARCADE,
  UPDATE_PLANOGRAM_ARCADE,
} from './actionTypes';

const INIT_STATE = {
  loading: true,
};

const BusinessPoint = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESULT:
      const newState = {
        ...state,
        loading: false,
      };
      switch (action.payload.actionType) {
        case INPUT_CSV:
          newState['inputCsv'] = action.payload.result;
          break;
        case UPDATE_SHARE_REVENUE:
          newState['updateShareRevenue'] = action.payload.result;
          break;
        case GET_SHARE_REVENUE:
          newState['getShareRevenue'] = action.payload.result;
          break;
        case LIST:
          newState['list'] = action.payload.result;
          break;
        case CLEAR_CASH:
          newState['clear_cash'] = action.payload.result;
          break;
        case HISTORY:
          newState['list_history'] = action.payload.result;
          break;
        case LIST_VM:
          newState['list_vm'] = action.payload.result;
          break;
        case LIST_PRODUCT:
          newState['list_product'] = action.payload.result;
          break;
        case INIT_CREATE:
          newState['initCreate'] = action.payload.result;
          break;
        case SERI_VM:
          newState['getSeriVM'] = action.payload.result;
          break;
        case STOCK_BUSINESS_POINT:
          newState['stockBusinessPoint'] = action.payload.result;
          break;
        case CREATE:
          newState['create'] = action.payload.result;
          break;
        case SET_PLANOGRAM_CSV:
          newState['setPlanogram_CSV'] = action.payload.result;
          break;
        case READ_INFO:
          newState['readInfo'] = action.payload.result;
          break;
        case UPDATE_INFO:
          newState['updateInfo'] = action.payload.result;
          break;
        case UPDATE_CALIBRATION:
          newState['updatecalibration'] = action.payload.result;
          break;
        case READ_PLANOGRAM:
          newState['readPlanogram'] = action.payload.result;
          break;
        case READ_PLANOGRAM_ARCADE:
          newState['readPlanogramArcade'] = action.payload.result;
          break;
        case READ_PLANOGRAM_LEVENDING:
          newState['readPlanogramLeVeding'] = action.payload.result;
          break;
        case READ_PLANOGRAM_MCPRO:
          newState['readPlanogramMCPro'] = action.payload.result;
          break;
        case READ_PLANOGRAM_FRANKE:
          newState['readPlanogramFranke'] = action.payload.result;
          break;
        case READ_PLANOGRAM_RETAIL:
          newState['readPlanogramRetail'] = action.payload.result;
          break;
        case READ_PLANOGRAM_RETAIL_OLD:
          newState['readPlanogramRetailOld'] = action.payload.result;
          break;
        case READ_PLANOGRAM_MILANO:
          newState['readPlanogramMilano'] = action.payload.result;
          break;
        case UPDATE_PLANOGRAM_MILANO:
          newState['updatePlanogramMilano'] = action.payload.result;
          break;
        case UPDATE_PLANOGRAM_MCPRO:
          newState['updatePlanogramMCPro'] = action.payload.result;
          break;
        case UPDATE_PLANOGRAM_FRANKE:
          newState['updatePlanogramFranke'] = action.payload.result;
          break;
        case UPDATE_PLANOGRAM_ARCADE:
          newState['updatePlanogramArcade'] = action.payload.result;
          break;
        case UPDATE_PLANOGRAM_LEVENDING:
          newState['updatePlanogramLevending'] = action.payload.result;
          break;
        case UPDATE_PLANOGRAM_RETAIL_OLD:
          newState['updatePlanogramRetailOld'] = action.payload.result;
          break;
        case READ_PRODUCT:
          newState['readproduct'] = action.payload.result;
          break;
        case UPDATE_PLANOGRAM:
          newState['updatePlanogram'] = action.payload.result;
          break;
        case UPDATE_PLANOGRAM_RETAIL:
          newState['updatePlanogramRetail'] = action.payload.result;
          break;
        case DELETE:
          newState['delete'] = action.payload.result;
          break;
        case RESET_FLOWMETER:
          newState['reset_flowmeter'] = action.payload.result;
          break;
        case UPDATE_PLANOGRAM_WATER_DISPENSER:
          newState['updatePlanogramWater'] = action.payload.result;
          break;
        case READ_PLANOGRAM_WATER_DISPENSER:
          newState['readPlanogramWater'] = action.payload.result;
          break;
      }
      return newState;

    default:
      return state;
  }
};

export default BusinessPoint;
