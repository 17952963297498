/* eslint-disable semi */
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';

// Product state
import { GET_VM_STATUS, GET_NETT_SETTLEMENT } from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { device_list as deviceMap } from '../../webservice/device';
import {
  has,
  safeDeepGet,
  safeDeepSet,
  safeDel,
} from 'iotera-base/utility/json';
import Result from 'iotera-base/result';

function* get_vm_status() {
  yield put(loading(GET_VM_STATUS));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);

  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const businessPointBody = wsResult.body;
    const businessPointList = [];
    const businessPointMap = {};
    for (const id in businessPointBody) {
      const deviceId = safeDeepGet(businessPointBody, [id, 'id'], '');
      businessPointMap[id] = safeDeepGet(businessPointBody, id);
    }
    const bpStatus = {
      bpTotal: 0,
      bpAlert: 0,
    };
    let i = 0;
    for (const deviceId in businessPointMap) {
      safeDeepSet(businessPointMap, [deviceId, 'id'], i++);
      // safeDeepSet(businessPointMap, [deviceId, 'online'], online);
      businessPointList.push(businessPointMap[deviceId]);
      bpStatus.bpTotal += 1;
    }
    var statusMap = { card: Object.values(bpStatus), map: businessPointList };

    yield put(result(GET_VM_STATUS, { code: Result.SUCCESS, body: statusMap }));
    return;
  }
  yield put(result(GET_VM_STATUS, { code: Result.FAILED }));
}

function* get_nett_settlement(payload) {
  yield put(loading(GET_NETT_SETTLEMENT));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.trxAdmin.nett_settlement, payload);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const nsCurrentMap = wsResult.body;
    // var payload_prev = payload;
    var payload_prev = {
      month: safeDeepGet(payload, 'month') - 1,
      year: safeDeepGet(payload, 'year'),
      date: [null, null]
    };
    safeDeepSet(payload_prev, ['month'], safeDeepGet(payload, ['month']) - 1);
    wsResult = yield call(
      firebaseInstance.trxAdmin.nett_settlement,
      payload_prev
    );
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const nsPrevMap = wsResult.body;
      let sum_per_current = sum_per_date(nsCurrentMap);
      let sum_current = sum_month(nsCurrentMap);
      let sum_prev = sum_month(nsPrevMap);
      let temp_change = 0.0;
      if (sum_prev == 0 && sum_current > 0) {
        temp_change = 100.0;
      } else if (sum_prev == 0 && sum_current == 0) {
        temp_change = 0.0;
      } else {
        temp_change = (sum_current / sum_prev - 1.0) * 100.0;
      }
      const nett_settlement = {
        card_values: {
          card_sum_current: sum_current,
          card_sum_prev: sum_prev,
          card_change: temp_change,
        },
        chart_data: sum_per_current,
      };
      yield put(
        result(GET_NETT_SETTLEMENT, {
          code: Result.SUCCESS,
          body: nett_settlement,
        })
      );
      return;
    }
  }
  yield put(result(GET_NETT_SETTLEMENT, { result: { code: Result.FAILED } }));
}

function* dashboardSaga() {
  yield takeEvery(GET_VM_STATUS, get_vm_status);
  yield takeEvery(GET_NETT_SETTLEMENT, get_nett_settlement);
}
function sum_month(nsMap) {
  var resultTotal = 0;
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    if (safeDeepGet(val, ['detail', 'transaction_status']) == 'credit') {
      resultTotal += val.payment.amount;
    }
  }
  return resultTotal;
}

function sum_per_date(nsMap) {
  let now = new Date();
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    {
      nsMap ? (now = new Date(val.time.timestamp)) : (now = new Date());
    }
  }
  var resultArr = [];
  var dateArr = [];
  const month = now.getMonth();
  const year = now.getFullYear();
  const date = new Date(year, month, 1);
  const dates = [];
  let obj = {};
  while (date.getMonth() === month) {
    const day = new Date(date);
    dates.push(('0' + day.getDate()).slice(-2));
    date.setDate(date.getDate() + 1);
  }
  for (const i in dates) {
    obj = { x: dates[i], y: 0 };
    resultArr.push(obj);
  }

  for (const ns in nsMap) {
    val = safeDeepGet(nsMap, [ns]);
    let val_date = new Date(val.time.timestamp).toLocaleString(undefined, {
      day: '2-digit',
    });
    var index = dateArr.indexOf(val_date);
    if (index == -1) {
      dateArr.push(val_date);
      if (safeDeepGet(val, ['detail', 'transaction_status']) == 'credit') {
        resultArr[Number(val_date) - 1].y += val.payment.amount;
      }
    } else {
      if (safeDeepGet(val, ['detail', 'transaction_status']) == 'credit') {
        resultArr[Number(val_date) - 1].y += val.payment.amount;
      }
    }
  }
  return resultArr;
}

export default dashboardSaga;
