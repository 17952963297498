/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Select from 'react-select';
// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Container,
  Input,
  Table,
  Modal,
  ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';

// Table
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

// Breadcrumbs
import Breadcrumbs from '../../components/Common/Breadcrumb';
// Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { NAME_PROJECT, buildTitle, APPLICATION_ID } from 'Apps';
// Redux
import { connect } from 'react-redux';
import {
  readPlanogram,
  updatePlanogram,
} from '../../store/business-point/actions';
import makeAnimated from 'react-select/animated';

import ReactExport from 'react-data-export';
import { CircularProgress } from '@material-ui/core';
import LoadingAnimation from 'Base/assets/LoadingAnimation';
import SuccessAnimation from 'Base/assets/SuccessAnimation';
import FailedAnimation from 'Base/assets/FailedAnimation';
let role = '';
const obj = JSON.parse(localStorage.getItem('role'));
if (obj != null) {
  role = obj.role;
}
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const animatedComponents = makeAnimated();
let buildColumns = [];
let buildColumnsPrice = [];

let buildColumns2 = [];
let buildPlanogramList = {};
let buildColumnList = {};
let validate = {};
let removed = [];

let dataInput = [];
class BusinessPointPlanogram extends Component {
  constructor(props) {
    super(props);
    const buttonList = [
      { label: 'KA1', value: 'KA1' },
      { label: 'KA2', value: 'KA2' },
      { label: 'KA3', value: 'KA3' },
      { label: 'KA4', value: 'KA4' },
      { label: 'KA5', value: 'KA5' },
      { label: 'KA6', value: 'KA6' },
      { label: 'KA7', value: 'KA7' },
      { label: 'KA8', value: 'KA8' },
      { label: 'KA9', value: 'KA9' },
      { label: 'KA10', value: 'KA10' },
      { label: 'KB1', value: 'KB1' },
      { label: 'KB2', value: 'KB2' },
      { label: 'KB3', value: 'KB3' },
      { label: 'KB4', value: 'KB4' },
      { label: 'KB5', value: 'KB5' },
      { label: 'KB6', value: 'KB6' },
      { label: 'KB7', value: 'KB7' },
      { label: 'KB8', value: 'KB8' },
      { label: 'KB9', value: 'KB9' },
      { label: 'KB10', value: 'KB10' },
    ];
    const buttonListRdboks = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: '6' },
      { label: '7', value: '7' },
      { label: '8', value: '8' },
      { label: '9', value: '9' },
      { label: '10', value: '10' },
      { label: '11', value: '11' },
      { label: '12', value: '12' },
      { label: '13', value: '13' },
      { label: '14', value: '14' },
      { label: '15', value: '15' },
      { label: '16', value: '16' },
      { label: '17', value: '17' },
      { label: '18', value: '18' },
      { label: '19', value: '19' },
      { label: '20', value: '20' },
    ];
    const buttonListFuji = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: '6' },
      { label: '7', value: '7' },
      { label: '8', value: '8' },
      { label: '9', value: '9' },
      { label: '10', value: '10' },
      { label: '11', value: '11' },
      { label: '12', value: '12' },
      { label: '13', value: '13' },
      { label: '14', value: '14' },
      { label: '15', value: '15' },
      { label: '16', value: '16' },
      { label: '17', value: '17' },
      { label: '18', value: '18' },
      { label: '19', value: '19' },
      { label: '20', value: '20' },
      { label: '21', value: '21' },
      { label: '22', value: '22' },
      { label: '23', value: '23' },
      { label: '24', value: '24' },
      { label: '25', value: '25' },
      { label: '26', value: '26' },
      { label: '27', value: '27' },
      { label: '28', value: '28' },
      { label: '29', value: '29' },
      { label: '30', value: '30' },
      { label: '31', value: '31' },
      { label: '32', value: '32' },
      { label: '33', value: '33' },
      { label: '34', value: '34' },
      { label: '35', value: '35' },
      { label: '36', value: '36' },
      { label: '37', value: '37' },
      { label: '38', value: '38' },
      { label: '39', value: '39' },
      { label: '40', value: '40' },
      { label: '41', value: '41' },
      { label: '42', value: '42' },
      { label: '43', value: '43' },
      { label: '44', value: '44' },
      { label: '45', value: '45' },
      { label: '46', value: '46' },
      { label: '47', value: '47' },
      { label: '48', value: '48' },
      { label: '49', value: '49' },
      { label: '50', value: '50' },
      { label: '51', value: '51' },
      { label: '52', value: '52' },
      { label: '53', value: '53' },
      { label: '54', value: '54' },
      { label: '55', value: '55' },
      { label: '56', value: '56' },
      { label: '57', value: '57' },
      { label: '58', value: '58' },
      { label: '59', value: '59' },
      { label: '60', value: '60' },
      { label: '60', value: '60' },
      { label: '61', value: '61' },
      { label: '62', value: '62' },
      { label: '63', value: '63' },
      { label: '64', value: '64' },
      { label: '65', value: '65' },
      { label: '66', value: '66' },
      { label: '67', value: '67' },
      { label: '68', value: '68' },
      { label: '69', value: '69' },
      { label: '70', value: '70' },
      { label: '71', value: '71' },
      { label: '72', value: '72' },
      { label: '73', value: '73' },
      { label: '74', value: '74' },
      { label: '75', value: '75' },
      { label: '76', value: '76' },
      { label: '77', value: '77' },
      { label: '78', value: '78' },
      { label: '79', value: '79' },
      { label: '80', value: '80' },
    ];
    this.state = {
      buttonValue: [],
      dataInput,
    };
    var editable = true;
    if (role == 'refiller') {
      editable = false;
    }
    const customStyles = {
      control: (base, state) => ({
        ...base,
        background: '#FDC8C8',
      }),
    };

    buildColumns = productMap => {
      const productList = [];
      const windowDimension = window.innerWidth;
      const isMobile = windowDimension <= 640;
      for (const sku in productMap) {
        productList.push({
          label: productMap[sku],
          value: sku,
        });
      }
      const mobile = [
        {
          dataField: 'selection',
          text: 'Selection',
          editable: false,
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },

          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'product',
          text: 'Product',
          // editable: false,
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              return productList;
            },
          },
          formatter: (cell, row, rowIndex, productMap) => (
            <>
              <p>{safeDeepGet(productMap, row.product, '')}</p>
            </>
          ),

          formatExtraData: productMap,
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'price',
          hidden: 'true',

          text: 'Price (Rp)',
          validator: (newValue, row, column) => {
            if (!/^\d+$/.test(newValue)) {
              return {
                valid: false,
              };
            }
            return true;
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'stock',
          text: 'Stock',
          validator: (newValue, row, column) => {
            if (!/^\d+$/.test(newValue)) {
              return {
                valid: false,
              };
            }
            return true;
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },

        {
          dataField: 'active',
          text: 'Active',
          hidden: 'true',
          editor: {
            type: Type.CHECKBOX,
          },
          formatter: (cell, row, rowIndex) => {
            return (
              <Input
                type="checkbox"
                defaultChecked={safeDeepGet(row, ['active'], 'off') === 'on'}
              />
            );
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
      ];
      const pc = [
        {
          dataField: 'selection',
          text: 'Selection',
          editable: false,
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },

          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'product',
          text: 'Product',
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              return productList;
            },
          },
          formatter: (cell, row, rowIndex, productMap) => (
            <>
              <p>{safeDeepGet(productMap, row.product, '')}</p>
            </>
          ),

          formatExtraData: productMap,
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'price',
          text: 'Price (Rp)',
          editable: editable,
          validator: (newValue, row, column) => {
            if (!/^\d+$/.test(newValue)) {
              return {
                valid: false,
              };
            }
            return true;
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'stock',
          text: 'Stock',
          validator: (newValue, row, column) => {
            if (!/^\d+$/.test(newValue)) {
              return {
                valid: false,
              };
            }
            return true;
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },

        {
          dataField: 'active',
          text: 'Active',
          editor: {
            type: Type.CHECKBOX,
          },
          formatter: (cell, row, rowIndex) => {
            return (
              <Input
                type="checkbox"
                defaultChecked={safeDeepGet(row, ['active'], 'off') === 'on'}
              />
            );
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
      ];
      let column = [];
      if (isMobile) {
        column = mobile;
      } else {
        column = pc;
      }
      return column;
    };
    buildColumnsPrice = productMap => {
      const productList = [];
      const windowDimension = window.innerWidth;
      const isMobile = windowDimension <= 640;
      for (const sku in productMap) {
        productList.push({
          label: productMap[sku],
          value: sku,
        });
      }
      const mobile = [
        {
          dataField: 'selection',
          text: 'Selection',
          editable: false,
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },

          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'price',
          text: 'Price (Rp)',
          validator: (newValue, row, column) => {
            if (!/^\d+$/.test(newValue)) {
              return {
                valid: false,
              };
            }
            return true;
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'active',
          text: 'Active',
          editor: {
            type: Type.CHECKBOX,
          },
          formatter: (cell, row, rowIndex) => {
            return (
              <Input
                type="checkbox"
                defaultChecked={safeDeepGet(row, ['active'], 'off') === 'on'}
              />
            );
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
      ];
      const price = mobile;
      return price;
    };
    buildColumns2 = productMap => {
      return [
        {
          dataField: 'selection',
          text: 'Button',
          editable: false,
          formatter: (cell, row, rowIndex, productMap) => (
            <>
              <p>{row.selection}</p>
            </>
          ),
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
                width: '100px',
              };
            }
            return {
              width: '100px',
            };
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'column',
          text: 'Column',
          editable: false,

          formatter: (cell, row, rowIndex, productMap) => (
            <>
              <Select
                defaultValue={row.dataInput[row.selection]}
                components={animatedComponents}
                closeMenuOnSelect={false}
                onChange={value => this.handleOnChange(value, row.selection)}
                options={
                  APPLICATION_ID == '1000000209'
                    ? buttonListRdboks
                    : this.state.buttonListFuji
                }
                isLoading={false}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Piih tombol..."
                styles={row.stockColumn <= 3 ? customStyles : ''}
              />
            </>
          ),

          formatExtraData: productMap,
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },

          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
      ];
    };

    const buildPlanogram = (selection, planogramMap) => {
      const product = safeDeepGet(planogramMap, [selection, 'product'], '');
      const nameProduct = safeDeepGet(
        planogramMap,
        [selection, 'nameProduct'],
        ''
      );
      const price = safeDeepGet(planogramMap, [selection, 'price'], 0);
      const stock = safeDeepGet(planogramMap, [selection, 'stock'], 0);
      const column = safeDeepGet(
        planogramMap,
        [selection.slice(1, 4), 'column'],
        ''
      );
      if (column) {
        var text = column.split(',');
        var arr_column = [];
      }
      for (const i in text) {
        arr_column.push({
          label: text[i],
          value: text[i],
        });
      }
      // this.state.dataInput['1'] = arr_column;
      this.state.dataInput[selection] = arr_column;
      const active =
        safeDeepGet(planogramMap, [selection, 'active'], false) === true
          ? 'on'
          : 'off';
      var stockColumn = 0;
      for (const value in arr_column) {
        const stock = safeDeepGet(
          planogramMap,
          [arr_column[value].value, 'stock'],
          0
        );
        stockColumn += stock;
      }
      return {
        key: selection,
        selection,
        product,
        nameProduct,
        price,
        stock,
        active,
        column,
        arr_column,
        stockColumn,
      };
    };

    buildPlanogramList = planogramMap => {
      const planogramList = [];
      const columnList = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'column'],
        []
      );
      for (const i in columnList) {
        const column = columnList[i];
        planogramList.push(buildPlanogram(column, planogramMap));
      }
      return planogramList;
    };
    buildColumnList = planogramMap => {
      const columnButtonList = [];
      const butttonList = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'button'],
        []
      );
      for (const i in butttonList) {
        const button = butttonList[i];
        columnButtonList.push(buildColumnButton(button, planogramMap));
      }
      return columnButtonList;
    };
    const buildColumnButton = (selection, planogramMap) => {
      const column = safeDeepGet(planogramMap, [selection, 'column'], '');
      if (column) {
        var text = column.split(',');
        var arr_column = [];
      }
      for (const i in text) {
        arr_column.push({
          label: text[i].toString(),
          value: text[i].toString(),
        });
      }
      const { dataInput } = this.state;
      dataInput[selection] = arr_column;
      return {
        key: selection,
        selection,
        column,
        dataInput,
        arr_column,
      };
    };
    const { data, type } = props;
    const deviceId = safeDeepGet(data, 'deviceId');
    const deviceType = type;
    const history = safeDeepGet(data, 'history');
    const massage = safeDeepGet(this.props, ['result', 'message'], {});
    this.state = {
      deviceType,
      listButton: buttonList,
      buttonListFuji: buttonListFuji,
      buttonValue: [],
      dataInput,
      massage,
      deviceId,
      columns: buildColumns({}),
      columnsPrice: buildColumnsPrice({}),

      columnsButton: buildColumns2({}),
      planogram: buildPlanogramList({}),
      // buttonColumns: buildColumnList({}),
      button: buildColumnList({}),
      productMap: {},
      isLoading: true,
      modalPlanogram: false,
      serverless_code: 20,
      planogram_history: [],
    };

    this.tableRef = React.createRef();
    this.buttonRef = React.createRef();
    this.priceRef = React.createRef();

    this.handleCancel = this.handleCancel.bind(this);
    // this.handleOnClick = this.handleOnClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidDate = this.handleValidDate.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.togglePlanogram = this.togglePlanogram.bind(this);
  }
  togglePlanogram() {
    this.setState(prevState => ({
      modalPlanogram: !prevState.modalPlanogram,
    }));
  }

  componentDidMount() {
    const { deviceId } = this.state;
    this.props.ReadPlanogram(deviceId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.readPlanogramResult !== prevProps.readPlanogramResult) {
      // console.log(this.props.readPlanogramResult)
      const code = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        const productMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'products'],
          {}
        );
        const planogramMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'planogram'],
          {}
        );
        const buttonOptions = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'buttonOptions'],
          []
        );
        const history = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'planogram'],
          []
        );
        const name = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'name'],
          []
        );
        const timeStamp = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'time_stamp'],
          0
        );
        const user = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'user'],
          []
        );
        this.setState({
          columns: buildColumns(productMap),
          columnsPrice: buildColumnsPrice(productMap),
          planogram: buildPlanogramList(planogramMap),
          button: buildColumnList(planogramMap),
          history: history,
          name: name,
          timeStamp: timeStamp,
          user: user,
          productMap,
          planogramMap,
          buttonOptions,
          isLoading: false,
        });
      }
    }
    if (this.props.result !== prevProps.result) {
      const code = safeDeepGet(
        this.props,
        ['result', 'code'],
        Result.UNKNOWN_ERROR
      );
      const message = safeDeepGet(
        this.props,
        ['result', 'message', 'text'],
        ''
      );
      const serverless_text = safeDeepGet(
        this.props,
        ['result', 'message', 'message'],
        message
      );
      const serverless_code = safeDeepGet(
        this.props,
        ['result', 'message', 'result'],
        Result.UNKNOWN_ERROR
      );
      const ts = safeDeepGet(
        this.props,
        ['result', 'ts'],
        new Date().getTime()
      );
      const planogram_history = safeDeepGet(
        this.props,
        ['result', 'planogram_history'],
        []
      );
      const name = safeDeepGet(this.props, ['result', 'name'], this.state.name);
      const mssage_serveless =
        'Message: ' + serverless_text + ' error code ' + serverless_code;
      this.setState({
        loading: false,
        serverless_code,
        ts,
        planogram_history,
        name,
      });
      // if (code === Result.SUCCESS) {
      //   this.showToast('Update Planogram Successful', code, message);
      //   setTimeout(() => window.location.reload(), 2000);
      // } else if (code !== Result.UNKNOWN_ERROR) {
      //   this.showToast('Update Planogram Failed', code, mssage_serveless);
      //   this.setState({ loading: false });
      //   setTimeout(() => window.location.reload(), 2000);
      // }
    }
  }
  showToast(messagee, code, txt) {
    if (messagee == 'Update Planogram Successful') {
      var toastType = 'success';
      var message = messagee;
    } else {
      var toastType = 'error';
      var message = messagee + ' Kode Error: ' + code + ' ' + txt;
    }
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleCancel(event) {
    history.push('/business-point/list');
  }
  handleOnClick(item) {}
  handleOnChange(value, selection) {
    const { listButton, buttonValue, dataInput } = this.state;
    const selectedValue = [];
    for (const i in value) {
      selectedValue.push(value[i]);
    }
    buttonValue[selection] = selectedValue;
    dataInput[selection] = selectedValue;

    safeDeepGet(buttonValue[selection], []);
    if (value.length == 0) {
      listButton.splice(0, 0, ...removed);
      removed = [];
    }
    for (const key in value) {
      var index = listButton.indexOf(value[key]);

      if (index !== -1) {
        const remove = listButton.splice(index, 1);
        removed.push(value[key]);
      }
    }
  }

  handleSubmit(event) {
    this.togglePlanogram();
    const { deviceId, productMap, dataInput, deviceType } = this.state;
    const data = this.tableRef.current.props.data;
    const button = this.buttonRef.current.props.data;
    const planogramMap = {};
    for (const i in data) {
      const planogram = data[i];
      const selection = planogram['selection'];
      const product = planogram['product'];
      // const product = planogram['product'];

      const arr_input = dataInput[selection];
      var str = '';
      for (const i in arr_input) {
        const data_arr = safeDeepGet(arr_input[i], ['value'], []);
        const j = Number(i);
        if (j + 1 == arr_input.length) {
          str += data_arr;
        } else {
          str += data_arr + ',';
        }
      }
      planogramMap[selection] = {
        product,
        // column: str,
        name: safeDeepGet(productMap, product, '-'),
        price: Number(planogram['price']),
        stock: Number(planogram['stock']),
        active: planogram['active'] == 'on',
      };
    }
    for (const i in button) {
      const planogram = button[i];
      const selection = planogram['selection'];
      const arr_input = dataInput[selection];
      var str = '';
      for (const i in arr_input) {
        const data_arr = safeDeepGet(arr_input[i], ['value'], []);
        const j = Number(i);
        if (j + 1 == arr_input.length) {
          str += data_arr;
        } else {
          str += data_arr + ',';
        }
      }
      planogramMap[selection] = {
        column: str,
      };
    }

    // console.log(planogramMap)
    this.props.UpdatePlanogram({
      id: deviceId,
      planogram: planogramMap,

      device_type: deviceType,
      latest_planogram: this.state.planogramMap,
    });
  }

  handleValidDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };

  render() {
    const loading = safeDeepGet(this.props, 'loading', true);
    const {
      columns,
      columnsPrice,
      planogram,
      columnsButton,
      dataInput,
      button,
      history,
      name,
      timeStamp,
      user,
      isLoading,
      serverless_code,
      ts,
      planogram_history,
    } = this.state;
    const dataButton = [];
    for (const selection in dataInput) {
      const arr_input = dataInput[selection];
      var str = '';
      for (const i in arr_input) {
        const data_arr = safeDeepGet(arr_input[i], ['value'], []);
        const j = Number(i);
        if (j + 1 == arr_input.length) {
          str += data_arr;
        } else {
          str += data_arr + ', ';
        }
      }
      if (selection.slice(0, 1) != 'K') {
        dataButton.push({ key: selection, value: str });
      }
    }
    const columnList = safeDeepGet(
      this.props,
      ['readPlanogramResult', 'column'],
      []
    );
    const type = safeDeepGet(this.props, ['readPlanogramResult', 'type'], []);
    const dataColumn = [];
    for (const selection in columnList) {
      const column = columnList[selection];
      dataColumn.push({ key: column, value: str });
    }

    const filename = name + '_' + '_' + this.handleValidDate(timeStamp);
    const windowDimension = window.innerWidth;
    const isMobile = windowDimension <= 640;
    const drole = localStorage.getItem('accessNav');
    const jsonRole = JSON.parse(drole);
    const guest = safeDeepGet(jsonRole, ['Business Point', 'guest'], 'off');
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Planoggram')}</title>
          </MetaTags>
          {isMobile ? (
            planogram.length > 0 ? (
              <Container fluid>
                <Breadcrumbs
                  title="Business Point"
                  breadcrumbItem="Planogram"
                />
                <Row>
                  <Col lx="8">
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4 h5">
                          Stock & Penempatan Produk
                        </CardTitle>
                        {isLoading ? (
                          <div className="text-center">
                            <CircularProgress className="text-center"></CircularProgress>
                          </div>
                        ) : (
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField="selection"
                              bootstrap4={true}
                              data={planogram}
                              columns={columns}
                              cellEdit={cellEditFactory({
                                mode: 'click',
                                blurToSave: true,
                              })}
                              bordered={false}
                              ref={this.tableRef}
                            />
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="4">
                    <Row></Row>
                    {/* <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Button
                        color="secondary"
                        onClick={this.handleCancel}
                        disabled={loading}
                      >
                        Cancel
                      </Button>{' '}
                      <Button
                        color="primary"
                        onClick={this.handleSubmit}
                        disabled={loading}
                      >
                        Simpan
                      </Button>
                    </div> */}
                  </Col>
                </Row>
                <Row>
                  <Col lx="8">
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4 h5">Harga Produk</CardTitle>
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="selection"
                            bootstrap4={true}
                            data={planogram}
                            columns={columnsPrice}
                            cellEdit={cellEditFactory({
                              mode: 'click',
                              blurToSave: true,
                            })}
                            bordered={false}
                            ref={this.tableRef}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="4">
                    <Row></Row>
                    <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Link
                        to="/business-point/list"
                        color="secondary"
                        className="btn btn-secondary"
                        role="button"
                        // onClick={this.handleCancel}
                        disabled={loading}
                      >
                        Cancel
                      </Link>{' '}
                      <Button
                        color="primary"
                        onClick={this.handleSubmit}
                        disabled={loading}
                      >
                        Simpan
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row></Row>
                <Row>
                  <Col lx="8">
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4 h5"></CardTitle>
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="selection"
                            // data={planogram}
                            columns={columnsButton}
                            data={button}
                            cellEdit={cellEditFactory({
                              mode: 'click',
                              blurToSave: true,
                            })}
                            bordered={false}
                            // ref={this.tableRef}
                            ref={this.buttonRef}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xl="4">
                    <Row></Row>
                    {/* <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Button
                        color="secondary"
                        onClick={this.handleCancel}
                        disabled={loading}
                      >
                        Cancel
                      </Button>{' '}
                      <Button
                        color="primary"
                        onClick={this.handleSubmit}
                        disabled={loading}
                      >
                        Simpan
                      </Button>
                    </div> */}
                  </Col>
                </Row>
              </Container>
            ) : (
              ' '
            )
          ) : (
            <Container fluid>
              <Breadcrumbs title="Business Point" breadcrumbItem="Planogram" />
              <Row>
                <Col lx="8">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h5">
                        Stock & Penempatan Produk
                      </CardTitle>
                      <div>
                        <p>
                          Untuk penggunaan double spiral, cukup setting
                          planogram pada salah satu spiral saja.
                        </p>
                      </div>
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField="selection"
                          bootstrap4={true}
                          data={planogram}
                          columns={columns}
                          cellEdit={cellEditFactory({
                            mode: 'click',
                            blurToSave: true,
                          })}
                          bordered={false}
                          ref={this.tableRef}
                        />
                      </div>
                    </CardBody>
                  </Card>

                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4 h5">
                          Latest Changes Planogram by
                        </CardTitle>

                        <Row className="align-items-center">
                          <Col lg="9" className="align-self-center">
                            <div className="table-responsive">
                              <Table className="table align-middle table-nowrap table-hover mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col">Email</th>
                                    <th scope="col">Time</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{user}</td>
                                    <td>
                                      {timeStamp == null
                                        ? '-'
                                        : this.handleValidDate(timeStamp)}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>

                          <Col
                            lg="3"
                            className="d-none d-lg-block"
                            align="center"
                          >
                            <div className="clearfix mt-4 mt-lg-0">
                              <ExcelFile
                                filename={filename}
                                element={
                                  <button
                                    type="button"
                                    className="btn btn-primary w-sm"
                                  >
                                    <i className="mdi mdi-download d-block font-size-16"></i>
                                    Download
                                  </button>
                                }
                              >
                                <ExcelSheet
                                  data={history}
                                  name="Transaction List"
                                >
                                  <ExcelColumn label="Selection" value="key" />
                                  <ExcelColumn label="Product" value="name" />
                                  <ExcelColumn
                                    label="Product SKU"
                                    value="product"
                                  />
                                  <ExcelColumn
                                    label="Product Price"
                                    value="price"
                                  />
                                  <ExcelColumn label="Stock" value="stock" />
                                  <ExcelColumn label="Active" value="active" />
                                </ExcelSheet>
                              </ExcelFile>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Col>
                <Col xl="4">
                  <Row>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4 h5">VM Layout</CardTitle>
                        <p className="card-title-desc">
                          Layout kolom mesin (referensi penempatan SKU)
                        </p>
                        <div className="card bg-primary text-white mb-0">
                          <CardBody>
                            <div className="table-responsive">
                              <Table className="table table-sm mb-0 text-white text-center">
                                <thead>
                                  {APPLICATION_ID == '1000000209' ? (
                                    <tr>
                                      <th colSpan="10">Kubota Top</th>
                                    </tr>
                                  ) : (
                                    <tr>
                                      <th colSpan="10">Top</th>
                                    </tr>
                                  )}
                                </thead>
                                {APPLICATION_ID == '1000000209' ? (
                                  <tbody>
                                    <tr>
                                      <td>K1</td>
                                      <td>K6</td>
                                      <td>K11</td>
                                      <td>K16</td>
                                    </tr>
                                    <tr>
                                      <td>K2</td>
                                      <td>K7</td>
                                      <td>K12</td>
                                      <td>K17</td>
                                    </tr>
                                    <tr>
                                      <td>K3</td>
                                      <td>K8</td>
                                      <td>K13</td>
                                      <td>K18</td>
                                    </tr>
                                    <tr>
                                      <td>K4</td>
                                      <td>K9</td>
                                      <td>K14</td>
                                      <td>K19</td>
                                    </tr>
                                    <tr>
                                      <td>K5</td>
                                      <td>K10</td>
                                      <td>K15</td>
                                      <td>K20</td>
                                    </tr>
                                  </tbody>
                                ) : type == 'kubota' ? (
                                  <tbody>
                                    <tr>
                                      <td>K1</td>
                                      <td>K6</td>
                                      <td>K11</td>
                                      <td>K16</td>
                                    </tr>
                                    <tr>
                                      <td>K2</td>
                                      <td>K7</td>
                                      <td>K12</td>
                                      <td>K17</td>
                                    </tr>
                                    <tr>
                                      <td>K3</td>
                                      <td>K8</td>
                                      <td>K13</td>
                                      <td>K18</td>
                                    </tr>
                                    <tr>
                                      <td>K4</td>
                                      <td>K9</td>
                                      <td>K14</td>
                                      <td>K19</td>
                                    </tr>
                                    <tr>
                                      <td>K5</td>
                                      <td>K10</td>
                                      <td>K15</td>
                                      <td>K20</td>
                                    </tr>
                                  </tbody>
                                ) : type == 'nagomi' ? (
                                  <tbody>
                                    <tr>
                                      {dataColumn.map(function (item, i) {
                                        return (
                                          <>
                                            {i > 5 ? null : (
                                              <td key={'A' + i}>{item.key}</td>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      {dataColumn.map(function (item, i) {
                                        return (
                                          <>
                                            {i > 5 ? (
                                              i < 12 ? (
                                                <td key={'A' + i}>
                                                  {item.key}
                                                </td>
                                              ) : null
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      {dataColumn.map(function (item, i) {
                                        return (
                                          <>
                                            {i > 11 ? (
                                              i < 19 ? (
                                                <td key={'A' + i}>
                                                  {item.key}
                                                </td>
                                              ) : null
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      {dataColumn.map(function (item, i) {
                                        return (
                                          <>
                                            {i > 18 ? (
                                              i < 26 ? (
                                                <td key={'A' + i}>
                                                  {item.key}
                                                </td>
                                              ) : null
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      {dataColumn.map(function (item, i) {
                                        return (
                                          <>
                                            {i > 25 ? (
                                              i < 33 ? (
                                                <td key={'A' + i}>
                                                  {item.key}
                                                </td>
                                              ) : null
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tr>
                                    {APPLICATION_ID == '1000000246' ? (
                                      <tr>
                                        <>
                                          <td>K51</td>
                                          <td>K52</td>
                                          <td>K53</td>
                                          <td>K54</td>
                                          <td>K55</td>
                                          <td>K56</td>
                                          <td>K57</td>
                                        </>
                                      </tr>
                                    ) : (
                                      <></>
                                    )}
                                  </tbody>
                                ) : type == 'slant' ? (
                                  <tbody>
                                    <tr>
                                      <td>K1</td>
                                      <td>K7</td>
                                      <td>K13</td>
                                      <td>K19</td>
                                      <td>K28</td>
                                    </tr>
                                    <tr>
                                      <td>K2</td>
                                      <td>K8</td>
                                      <td>K14</td>
                                      <td>K20</td>
                                      <td>K29</td>
                                    </tr>
                                    <tr>
                                      <td>K3</td>
                                      <td>K9</td>
                                      <td>K15</td>
                                      <td>K21</td>
                                      <td>K30</td>
                                    </tr>
                                    <tr>
                                      <td>K4</td>
                                      <td>K10</td>
                                      <td>K16</td>
                                      <td>K22</td>
                                      <td>K31</td>
                                    </tr>
                                    <tr>
                                      <td>K5</td>
                                      <td>K11</td>
                                      <td>K17</td>
                                      <td>K23</td>
                                      <td>K32</td>
                                    </tr>
                                    <tr>
                                      <td>K6</td>
                                      <td>K12</td>
                                      <td>K18</td>
                                      <td>K24</td>
                                      <td>K33</td>
                                    </tr>
                                    <tr>
                                      <td>{'⁣'}</td>
                                      <td>{'⁣'}</td>
                                      <td>{'⁣'}</td>
                                      <td>K25</td>
                                      <td>K34</td>
                                    </tr>
                                    <tr>
                                      <td>{'⁣'}</td>
                                      <td>{'⁣'}</td>
                                      <td>{'⁣'}</td>
                                      <td>K26</td>
                                      <td>K35</td>
                                    </tr>
                                    <tr>
                                      <td>{'⁣'}</td>
                                      <td>{'⁣'}</td>
                                      <td>{'⁣'}</td>
                                      <td>K27</td>
                                      <td>K36</td>
                                    </tr>
                                  </tbody>
                                ) : (
                                  <tbody>
                                    <tr>
                                      {dataColumn.map(function (item, i) {
                                        return (
                                          <>
                                            {i > 9 ? null : (
                                              <td key={'A' + i}>{item.key}</td>
                                            )}
                                          </>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      {dataColumn.map(function (item, i) {
                                        return (
                                          <>
                                            {i > 9 ? (
                                              i < 20 ? (
                                                <td key={'A' + i}>
                                                  {item.key}
                                                </td>
                                              ) : null
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      {dataColumn.map(function (item, i) {
                                        return (
                                          <>
                                            {i > 19 ? (
                                              i < 30 ? (
                                                <td key={'A' + i}>
                                                  {item.key}
                                                </td>
                                              ) : null
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      {dataColumn.map(function (item, i) {
                                        return (
                                          <>
                                            {i > 29 ? (
                                              i < 40 ? (
                                                <td key={'A' + i}>
                                                  {item.key}
                                                </td>
                                              ) : null
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      {dataColumn.map(function (item, i) {
                                        return (
                                          <>
                                            {i > 39 ? (
                                              i < 50 ? (
                                                <td key={'A' + i}>
                                                  {item.key}
                                                </td>
                                              ) : null
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      {dataColumn.map(function (item, i) {
                                        return (
                                          <>
                                            {i > 49 ? (
                                              i < 60 ? (
                                                <td key={'A' + i}>
                                                  {item.key}
                                                </td>
                                              ) : null
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tr>
                                    <tr>
                                      {dataColumn.map(function (item, i) {
                                        return (
                                          <>
                                            {i > 59 ? (
                                              i < 70 ? (
                                                <td key={'A' + i}>
                                                  {item.key}
                                                </td>
                                              ) : null
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tr>
                                  </tbody>
                                )}
                              </Table>
                            </div>
                          </CardBody>
                        </div>
                      </CardBody>
                    </Card>
                  </Row>
                  <Row></Row>
                  <div
                    style={{
                      // backgroundColor: 'blue',
                      position: 'fixed',
                      bottom: '20px',
                      right: '80px',
                      // width: '600px',
                      height: '50px',
                      // display: 'flex',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      // boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)',
                      zIndex: 999,
                    }}
                  >
                    <Row>
                      <Col
                        sm="6"
                        className="mt-3"
                        style={{
                          padding: '0px',
                          width: '200px',
                        }}
                        s
                      >
                        <p className="text-start" style={{ fontSize: '10px' }}>
                          Latest Planogram{' '}
                          {timeStamp == null
                            ? '-'
                            : this.handleValidDate(timeStamp)}
                        </p>
                      </Col>
                      <Col
                        sm="6"
                        style={{
                          padding: '0px',
                          width: '200px',
                        }}
                      >
                        <div className="d-flex flex-wrap gap-2 float-end ">
                          <Link
                            to="/business-point/list"
                            color="secondary"
                            className="btn btn-secondary"
                            role="button"
                            // onClick={this.handleCancel}
                            disabled={loading}
                          >
                            Cancel
                          </Link>{' '}
                          {guest == 'off' ? (
                            <Button
                              color="primary"
                              onClick={this.handleSubmit}
                              disabled={loading}
                            >
                              Simpan
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Link
                      to="/business-point/list"
                      color="secondary"
                      className="btn btn-secondary"
                      role="button"
                      // onClick={this.handleCancel}
                      disabled={loading}
                    >
                      Cancel
                    </Link>{' '}
                    <Button
                      color="primary"
                      onClick={this.handleSubmit}
                      disabled={loading}
                    >
                      Simpan
                    </Button>
                  </div> */}
                </Col>
              </Row>
              <Row>
                <Col lx="8">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h5"></CardTitle>
                      <div>
                        <p>
                          Untuk mesin tipe Nagomi dan Big-Nagomi tidak perlu
                          mengisi button column dibawah ini
                        </p>
                      </div>
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField="selection"
                          // data={planogram}
                          columns={columnsButton}
                          data={button}
                          cellEdit={cellEditFactory({
                            mode: 'click',
                            blurToSave: true,
                          })}
                          bordered={false}
                          // ref={this.tableRef}
                          ref={this.buttonRef}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="4">
                  <Row>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4 h5">Column Layout</CardTitle>
                        <p className="card-title-desc">Layout kolom mesin</p>
                        <div className="card bg-primary text-white mb-0">
                          <CardBody>
                            <div className="table-responsive">
                              <Table className="table table-sm mb-0 text-white text-center">
                                <thead>
                                  <tr>
                                    <th colSpan="10">Column</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    {dataButton.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 19 ? (
                                            i < 30 ? (
                                              <td key={'A' + i}>{item.key}</td>
                                            ) : null
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </tr>
                                  <tr>
                                    {dataButton.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 9 ? (
                                            i < 20 ? (
                                              <td key={'A' + i}>{item.key}</td>
                                            ) : null
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </tr>
                                  <tr>
                                    {dataButton.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 9 ? null : (
                                            <td key={'A' + i}>{item.key}</td>
                                          )}
                                        </>
                                      );
                                    })}
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </div>
                      </CardBody>
                    </Card>
                  </Row>
                  <Row></Row>
                  {/* <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Button
                      color="secondary"
                      onClick={this.handleCancel}
                      disabled={loading}
                    >
                      Cancel
                    </Button>{' '}
                    <Button
                      color="primary"
                      onClick={this.handleSubmit}
                      disabled={loading}
                    >
                      Simpan
                    </Button>
                  </div> */}
                </Col>
              </Row>
              <Modal
                key="modal"
                size="lg"
                isOpen={this.state.modalPlanogram}
                className={this.props.className}
                toggle={this.togglePlanogram}
                fullscreen
              >
                {/* <ModalHeader toggle={this.togglePlanogram} tag="h4"></ModalHeader> */}
                <ModalBody>
                  <div className=" text-left">
                    {serverless_code == Result.NETWORK_FAILURE ? (
                      <FailedAnimation />
                    ) : serverless_code == Result.TIMEOUT ? (
                      <FailedAnimation />
                    ) : serverless_code == -11 ? (
                      <FailedAnimation />
                    ) : serverless_code == Result.FAILED ? (
                      <FailedAnimation />
                    ) : serverless_code == Result.INPUT_FAILURE ? (
                      <FailedAnimation />
                    ) : serverless_code == 0 ? (
                      <SuccessAnimation />
                    ) : (
                      <LoadingAnimation />
                    )}
                    {serverless_code == 20 ? (
                      <h3 className="text-center">
                        Sedang memproses data, mohon tunggu sebentar...
                      </h3>
                    ) : (
                      <div>
                        <Row>
                          <Col sm="4"></Col>
                          <Col sm="4">
                            <Card className="border-solid border-black">
                              <div className="table-responsive mb-2">
                                <Table className="border-solid border-2 table align-middle table-wrap">
                                  <thead>
                                    <tr>
                                      <th scope="col" className="text-start">
                                        <div>
                                          <h5 className="font-size-14">
                                            Planogram ID:
                                          </h5>
                                        </div>
                                      </th>
                                      <th scope="row" className="text-end">
                                        <div>
                                          <h5 className="font-size-14">{ts}</h5>
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <th scope="col" className="text-start">
                                        <div>
                                          <h5 className="font-size-14">
                                            Update By:
                                          </h5>
                                        </div>
                                      </th>
                                      <th scope="row" className="text-end">
                                        <div>
                                          <h5 className="font-size-14">
                                            {localStorage.getItem('email')}
                                          </h5>
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <th scope="col" className="text-start">
                                        <div>
                                          <h5 className="font-size-14">
                                            Update Time:
                                          </h5>
                                        </div>
                                      </th>
                                      <th scope="row" className="text-end">
                                        <div>
                                          <h5 className="font-size-14">
                                            {this.handleValidDate(ts)}
                                          </h5>
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <th scope="col" className="text-start">
                                        <div>
                                          <h5 className="font-size-14">
                                            Status:
                                          </h5>
                                        </div>
                                      </th>
                                      <th scope="row" className="text-end">
                                        <div>
                                          {serverless_code ===
                                          Result.NETWORK_FAILURE ? (
                                            <h5 className="font-size-14">
                                              Proses gagal, mesin sedang
                                              offline.
                                            </h5>
                                          ) : serverless_code ===
                                            Result.TIMEOUT ? (
                                            <h5 className="font-size-14">
                                              Proses gagal, mesin tidak
                                              memberikan respon.
                                            </h5>
                                          ) : serverless_code === -11 ? (
                                            <h5 className="font-size-14">
                                              Proses gagal, mesin sedang sibuk
                                              melakukan transaksi.
                                            </h5>
                                          ) : serverless_code ===
                                            Result.FAILED ? (
                                            <h5 className="font-size-14">
                                              Proses gagal, mesin tidak
                                              merespons atau sedang offline.
                                            </h5>
                                          ) : serverless_code ===
                                            Result.INPUT_FAILURE ? (
                                            <h5 className="font-size-14">
                                              Proses gagal, format planogram
                                              salah. Silakan hubungi Tim Iotera.
                                            </h5>
                                          ) : serverless_code ===
                                            Result.SUCCESS ? (
                                            <h5 className="font-size-14">
                                              Proses Selesai, mesin telah di
                                              update dengan planogram baru.
                                            </h5>
                                          ) : (
                                            <h5 className="font-size-14">
                                              Sedang memproses data, mohon
                                              tunggu sebentar...
                                            </h5>
                                          )}
                                        </div>
                                      </th>
                                    </tr>
                                    {serverless_code === Result.SUCCESS ? (
                                      <tr>
                                        <th scope="col" className="text-start">
                                          <div>
                                            <h5 className="font-size-14">
                                              Planogram data
                                            </h5>
                                          </div>
                                        </th>
                                        <th scope="row" className="text-end">
                                          <div className="clearfix mt-4 mt-lg-0">
                                            <ExcelFile
                                              filename={
                                                name +
                                                '_' +
                                                this.handleValidDate(ts)
                                              }
                                              element={
                                                <button
                                                  type="button"
                                                  className="btn btn-primary w-sm"
                                                >
                                                  <i className="mdi mdi-download d-block font-size-16"></i>
                                                  Download
                                                </button>
                                              }
                                            >
                                              {APPLICATION_ID == '1000000211' ||
                                              APPLICATION_ID == '1000000226' ? (
                                                <ExcelSheet
                                                  data={planogram_history}
                                                  name="Planogram History"
                                                >
                                                  <ExcelColumn
                                                    label="selection"
                                                    value="selection"
                                                    numFmt="0"
                                                  />
                                                  <ExcelColumn
                                                    label="sku"
                                                    value="sku"
                                                  />
                                                  <ExcelColumn
                                                    label="name"
                                                    value="name"
                                                  />
                                                  <ExcelColumn
                                                    label="price"
                                                    value="price"
                                                    numFmt="0"
                                                  />
                                                </ExcelSheet>
                                              ) : APPLICATION_ID ==
                                                '1000000258' ? (
                                                <ExcelSheet
                                                  data={planogram_history}
                                                  name="Planogram History"
                                                >
                                                  <ExcelColumn
                                                    label="pulse"
                                                    value="pulse"
                                                    numFmt="0"
                                                  />
                                                  <ExcelColumn
                                                    label="duration"
                                                    value="duration"
                                                  />
                                                  <ExcelColumn
                                                    label="price"
                                                    value="price"
                                                    numFmt="0"
                                                  />
                                                </ExcelSheet>
                                              ) : (
                                                <ExcelSheet
                                                  data={planogram_history}
                                                  name="Planogram History"
                                                >
                                                  <ExcelColumn
                                                    label="Selection"
                                                    value="key"
                                                  />
                                                  <ExcelColumn
                                                    label="Product"
                                                    value="name"
                                                  />
                                                  <ExcelColumn
                                                    label="Product SKU"
                                                    value="product"
                                                  />
                                                  <ExcelColumn
                                                    label="Product Price"
                                                    value="price"
                                                    numFmt="0"
                                                  />
                                                  <ExcelColumn
                                                    label="Stock"
                                                    value="stock"
                                                  />
                                                  <ExcelColumn
                                                    label="Active"
                                                    value="active"
                                                  />
                                                  <ExcelColumn
                                                    label="Column"
                                                    value="column"
                                                  />
                                                </ExcelSheet>
                                              )}
                                            </ExcelFile>
                                          </div>
                                        </th>
                                      </tr>
                                    ) : (
                                      <></>
                                    )}
                                  </thead>
                                </Table>
                              </div>
                            </Card>
                          </Col>
                          <Col sm="4"></Col>
                        </Row>
                      </div>
                    )}
                  </div>
                  <div className="text-center mt-4">
                    {serverless_code == Result.SUCCESS ? (
                      <Link
                        to={'/business-point/history/planogram'}
                        style={{
                          color: 'white',
                        }}
                        className="mb-2 btn btn-primary"
                      >
                        Next
                      </Link>
                    ) : serverless_code == 20 ? (
                      <></>
                    ) : (
                      <Button color="primary" onClick={this.togglePlanogram}>
                        Close
                      </Button>
                    )}
                  </div>
                </ModalBody>
              </Modal>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  readPlanogramResult: safeDeepGet(
    state,
    ['businessPoint', 'readPlanogram'],
    {}
  ),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  result: safeDeepGet(state, ['businessPoint', 'updatePlanogram'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  ReadPlanogram: id => dispatch(readPlanogram(id)),
  UpdatePlanogram: payload => dispatch(updatePlanogram(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessPointPlanogram);
