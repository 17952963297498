/* eslint-disable semi */
import {
  LOADING,
  RESULT,
  LIST,
  LIST_VM,
  SERI_VM,
  LIST_PRODUCT,
  INIT_CREATE,
  CREATE,
  READ_INFO,
  UPDATE_INFO,
  SET_PLANOGRAM_CSV,
  CLEAR_CASH,
  DELETE,
  READ_PRODUCT,
  HISTORY,
  UPDATE_CALIBRATION,
  NOTIFICATIONS,
  CLOSE_NOTIFICATIONS,
  INPUT_CSV,
  STOCK_BUSINESS_POINT,
  UPDATE_PLANOGRAM_MCPRO,
  UPDATE_PLANOGRAM_MILANO,
  UPDATE_PLANOGRAM_RETAIL,
  UPDATE_PLANOGRAM_LEVENDING,
  UPDATE_PLANOGRAM,
  UPDATE_PLANOGRAM_RETAIL_OLD,
  UPDATE_PLANOGRAM_FRANKE,
  READ_PLANOGRAM_RETAIL,
  READ_PLANOGRAM_LEVENDING,
  READ_PLANOGRAM_RETAIL_OLD,
  READ_PLANOGRAM_MILANO,
  READ_PLANOGRAM,
  READ_PLANOGRAM_FRANKE,
  READ_PLANOGRAM_MCPRO,
  UPDATE_SHARE_REVENUE,
  GET_SHARE_REVENUE,
  UPDATE_PLANOGRAM_WATER_DISPENSER,
  READ_PLANOGRAM_WATER_DISPENSER,
  RESET_FLOWMETER,
  READ_PLANOGRAM_ARCADE,
  UPDATE_PLANOGRAM_ARCADE,
} from "./actionTypes";
export const stockBusinessPoint = payload => ({
  type: STOCK_BUSINESS_POINT,
  payload
});
export const loading = actionType => ({
  type: LOADING,
  payload: { actionType }
});
export const inputCsv = payload => ({
  type: INPUT_CSV,
  payload
});
export const setPlanogram_CSV = payload => ({
  type: SET_PLANOGRAM_CSV,
  payload
});
export const result = (actionType, result) => ({
  type: RESULT,
  payload: { actionType, result }
});

export const list = payload => ({
  type: LIST,
  payload
});
export const list_history = payload => ({
  type: HISTORY,
  payload
});
export const getSeriVM = payload => ({
  type: SERI_VM,
  payload
});
export const list_vm = () => ({
  type: LIST_VM,
});
export const list_product = () => ({
  type: LIST_PRODUCT,
});

export const initCreate = () => ({
  type: INIT_CREATE,
});

export const create = payload => ({
  type: CREATE,
  payload
});
export const notification = () => ({
  type: NOTIFICATIONS,
});
export const close_notification = payload => ({
  type: CLOSE_NOTIFICATIONS,
  payload
});
export const readInfo = id => ({
  type: READ_INFO,
  payload: { id }
});

export const updateInfo = payload => ({
  type: UPDATE_INFO,
  payload
});
export const updateShareRevenue= payload => ({
  type: UPDATE_SHARE_REVENUE,
  payload
});
export const getShareRevenue= payload => ({
  type: GET_SHARE_REVENUE,
  payload
});
export const updatecalibration = payload => ({
  type: UPDATE_CALIBRATION,
  payload
});
export const reset_flowmeter = payload => ({
  type: RESET_FLOWMETER,
  payload
});
export const updatePlanogramWater = payload => ({
  type: UPDATE_PLANOGRAM_WATER_DISPENSER,
  payload
});
export const readPlanogramWater = id => ({
  type: READ_PLANOGRAM_WATER_DISPENSER,
  payload: { id }
});
export const readPlanogram = id => ({
  type: READ_PLANOGRAM,
  payload: { id }
});
export const readPlanogramLeVeding = id => ({
  type: READ_PLANOGRAM_LEVENDING,
  payload: { id }
});
export const readPlanogramFranke = id => ({
  type: READ_PLANOGRAM_FRANKE,
  payload: { id }
});
export const readPlanogramRetail = id => ({
  type: READ_PLANOGRAM_RETAIL,
  payload: { id }
});
export const readPlanogramMCPro = id => ({
  type: READ_PLANOGRAM_MCPRO,
  payload: { id }
});
export const readPlanogramArcade = id => ({
  type: READ_PLANOGRAM_ARCADE,
  payload: { id }
});
export const readPlanogramMilano = id => ({
  type: READ_PLANOGRAM_MILANO,
  payload: { id }
});

export const readproduct = id => ({
  type: READ_PRODUCT,
  payload: { id }
});

export const updatePlanogram = payload => ({
  type: UPDATE_PLANOGRAM,
  payload
});
export const updatePlanogramFranke = payload => ({
  type: UPDATE_PLANOGRAM_FRANKE,
  payload
});
export const updatePlanogramArcade = payload => ({
  type: UPDATE_PLANOGRAM_ARCADE,
  payload
});
export const updatePlanogramLevending = payload => ({
  type: UPDATE_PLANOGRAM_LEVENDING,
  payload
});
export const updatePlanogramMCPro = payload => ({
  type: UPDATE_PLANOGRAM_MCPRO,
  payload
});
export const updatePlanogramMilano = payload => ({
  type: UPDATE_PLANOGRAM_MILANO,
  payload
});
export const updatePlanogramRetail = payload => ({
  type: UPDATE_PLANOGRAM_RETAIL,
  payload
});
export const updatePlanogramRetailOld = payload => ({
  type: UPDATE_PLANOGRAM_RETAIL_OLD,
  payload
});
export const readPLanogramRetailOld = payload => ({
  type: READ_PLANOGRAM_RETAIL_OLD,
  payload
});
export const clear_cash = payload => ({
  type: CLEAR_CASH,
  payload
});

export const delete_ = payload => ({
  type: DELETE,
  payload: { deviceId: payload.id, name: payload.name }
});
