import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"


// Dashboard 
import dashboard from "./dashboard/reducer"

// Business point
import businessPoint from "./business-point/reducer"

// Product
// import product from "./product/reducer";

//Payout
// import payout from "./payout/reducer";
// Transaction
// import transaction from "./transaction/reducer";

// import chat from "./chat/reducer"
// import refiller from "./refiller/reducer"
// import coupon from "./coupon/reducer"
import User from "./User/reducer"
import Mapping from "./Mapping/reducer"
import BpFee from "./business-point-fee/reducer"
import Project from "./Project/reducer"
import transaction_admin from "./transaction-admin/reducer"
import payoutAdmin from "./Payout/reducer"
import dashboardAdmin from "./dashboard/reducer"
import tags from "./tags/reducer"
import PaymentLink from "./payment-link/reducer"
const rootReducer = combineReducers({
  // public
  PaymentLink,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  dashboard,
  businessPoint,
  User,
  Mapping,
  BpFee,
  Project,
  transaction_admin,
  payoutAdmin,
  dashboardAdmin,
  tags
})

export default rootReducer
