/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable react/jsx-key */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import 'flatpickr/dist/themes/material_blue.css';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Link } from 'react-router-dom';

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Modal,
  Label,
  CardTitle,
  ModalHeader,
  Table,
  FormGroup,
  Collapse,
  ModalBody,
  Progress,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { APPLICATION_ID, NAME_PROJECT, buildTitle } from 'Apps';
//Import Breadcrumb
// Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';

//CSV File
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
// Redux
import { connect } from 'react-redux';
import {
  updatePlanogramWater,
  readPlanogramWater,
} from '../../store/business-point/actions';
import { CircularProgress } from '@material-ui/core';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import planogram from './planogram';
import FailedAnimation from 'Base/assets/FailedAnimation';
import SuccessAnimation from 'Base/assets/SuccessAnimation';
import LoadingAnimation from 'Base/assets/LoadingAnimation';
class PlanogramList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    // let deviceId = null;
    const { data, type } = props;
    const deviceId = data;
    const deviceType = type;
    this.state = {
      planogram: {},
      col2: false,
      modalDetail: false,
      deviceId: deviceId,
      deviceType,
      bpfilterlist: [],
      businessPoints: 'all',
      transactionStatus: 'all',
      dispenseStatus: 'all',
      file: '',
      array: [],
      headerTable: '',
      valuesArray: '',
      isLoading: true,
      modalPlanogram: false,
      serverless_code: 20,
      planogram_history: [],
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleImportClick = this.handleImportClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.toggleDetail = this.toggleDetail.bind(this);
    this.togglePlanogram = this.togglePlanogram.bind(this);
  }
  togglePlanogram() {
    this.setState(prevState => ({
      modalPlanogram: !prevState.modalPlanogram,
    }));
  }
  t_col2() {
    this.setState({
      col2: !this.state.col2,
    });
  }
  toggleDetail() {
    this.setState(prevState => ({
      modalDetail: !prevState.modalDetail,
    }));
  }
  handleImportClick = row => {
    this.toggleDetail();
  };
  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };
  componentDidMount() {
    const { deviceId } = this.state;
    this.props.ReadPlanogram(deviceId);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };
  handleOnChange = e => {
    const file = e.target.files[0];
    if (file.size > 5000) {
      this.setState({ file });
    } else {
      this.setState({ file });
    }
    // let valuesArray =[]
    Papa.parse(e.target.files[0], {
      header: true,
      complete: function (results) {
        for (const i in results) {
          const valuesArray = results['data'];
          this.setState({ valuesArray });
        }
        const rowsArray = [];
        // Iterating data to get column name and their values
        results.data.map(d => {
          rowsArray.push(Object.keys(d));
        });
        this.setState({ rowsArray });
      }.bind(this),
    });
  };

  handleSubmit(event, values) {
    this.togglePlanogram();
    const payload = {
      device_id: this.state.deviceId,
      device_type: this.state.deviceType,
      ...values,
    };
    this.setState({ loading: true });
    this.props.InputPlanogram(payload);
  }
  showToastNoProduct() {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };
    toastr.error('Terdapat Produk yang tidak terdaftar');
  }
  showToastFailed(code, message) {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };
    toastr.error('Gagal Update Planogram : ' + code + ' ' + message);
  }
  showToast() {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };
    toastr.success('Berhasil Update Planogram');
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['createResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      const message = safeDeepGet(
        this.props,
        ['createResult', 'message', 'text'],
        ''
      );
      const serverless_text = safeDeepGet(
        this.props,
        ['createResult', 'message', 'message'],
        message
      );
      const serverless_code = safeDeepGet(
        this.props,
        ['createResult', 'message', 'result'],
        Result.UNKNOWN_ERROR
      );
      const ts = safeDeepGet(
        this.props,
        ['result', 'ts'],
        new Date().getTime()
      );
      const planogram_history = safeDeepGet(
        this.props,
        ['result', 'planogram_history'],
        []
      );
      const name = safeDeepGet(this.props, ['result', 'name'], this.state.name);
      const mssage_serveless =
        'Message: ' + serverless_text + ' error code ' + serverless_code;
      this.setState({
        loading: false,
        serverless_code,
        ts,
        planogram_history,
        name,
      });
      if (code != 999) {
        if (code === Result.SUCCESS || code == Result.HTTP.OK) {
          // this.showToast();
          this.componentDidMount();
        } else if (code === '-4') {
          // this.showToastNoProduct(code, mssage_serveless);
        } else {
          // this.showToastFailed(code, mssage_serveless);
        }
      }
    }
    if (this.props.readPlanogramResult !== prevProps.readPlanogramResult) {
      const code = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        const planogramMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'planogram'],
          {}
        );
        const history = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'planogram'],
          []
        );
        const name = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'name'],
          []
        );
        const timeStamp = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'time_stamp'],
          0
        );
        const user = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'user'],
          []
        );
        this.setState({
          history: history,
          name: name,
          timeStamp: timeStamp,
          user: user,
          planogram: planogramMap,
          isLoading: false,
        });
      }
    }
  }
  handledDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };
  render() {
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ['readPlanogramResult', 'code'],
      Result.UNKNOWN_ERROR
    );
    const defaultSorted = [
      {
        dataField: 'order', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc', // desc or asc
      },
    ];
    const formValues = safeDeepGet(
      this.props,
      ['readPlanogramResult', 'body', 'planogram'],
      []
    );
    const drole = localStorage.getItem('accessNav');
    const jsonRole = JSON.parse(drole);
    const guest = safeDeepGet(jsonRole, ['Business Point', 'guest'], 'off');
    const nameBP = safeDeepGet(
      this.props,
      ['readPlanogramResult', 'body', 'name'],
      ''
    );
    const { history, name, timeStamp, user, loading, serverless_code,ts, planogram_history } = this.state;
    const filename = name + '_' + '_' + this.handledDate(timeStamp);
    const { planogram, isLoading } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Planogram')}</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <AvForm onValidSubmit={this.handleSubmit}>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      {isLoading ? (
                        <div className="text-center">
                          <CircularProgress className="text-center"></CircularProgress>
                        </div>
                      ) : (
                        <Row>
                          <Col sm={6}>
                            <FormGroup className="mb-3">
                              <h3> Cup + Water</h3>
                              <AvField
                                value={safeDeepGet(
                                  planogram,
                                  ['water_cup', 'price'],
                                  999999999
                                )}
                                name="priceCup"
                                label="Harga"
                                placeholder="Harga Per sesi"
                                type="number"
                                errorMessage="Masukkan Harga Dengan Benar"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                              <AvField
                                value={safeDeepGet(
                                  planogram,
                                  ['water_cup', 'duration'],
                                  999999999
                                )}
                                name="durationCup"
                                label="Durasi"
                                placeholder="Durasi Per sesi"
                                type="number"
                                errorMessage="Masukkan Durasi Dengan Benar"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                              <AvField
                                value={safeDeepGet(
                                  planogram,
                                  ['water_cup', 'stock'],
                                  999999999
                                )}
                                name="stockCup"
                                label="Stock"
                                placeholder="Stock Per sesi"
                                type="number"
                                errorMessage="Masukkan Durasi Dengan Benar"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup className="mb-3">
                              <h3> Water</h3>
                              <AvField
                                value={safeDeepGet(
                                  planogram,
                                  ['water', 'price'],
                                  999999999
                                )}
                                name="priceWater"
                                label="Harga"
                                placeholder="Harga Per sesi"
                                type="number"
                                errorMessage="Masukkan Harga Dengan Benar"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                              <AvField
                                value={safeDeepGet(
                                  planogram,
                                  ['water', 'duration'],
                                  999999999
                                )}
                                name="durationWater"
                                label="Durasi"
                                placeholder="Durasi Per sesi"
                                type="number"
                                errorMessage="Masukkan Durasi Dengan Benar"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                              <AvField
                                value={safeDeepGet(
                                  planogram,
                                  ['flowmeter', 'total'],
                                  1
                                )}
                                name="totalFlow"
                                label="Total Water"
                                type="number"
                                className="form-control"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                  <div
                    style={{
                      position: 'fixed',
                      bottom: '20px',
                      right: '80px',
                      height: '50px',
                      zIndex: 999,
                    }}
                  >
                    <Row>
                      <Col
                        sm="6"
                        className="mt-3"
                        style={{
                          padding: '0px',
                          width: '200px',
                        }}
                        s
                      >
                        <p className="text-start" style={{ fontSize: '10px' }}>
                          Latest Planogram{' '}
                          {timeStamp == null
                            ? '-'
                            : this.handledDate(timeStamp)}
                        </p>
                      </Col>
                      <Col
                        sm="6"
                        style={{
                          padding: '0px',
                          width: '200px',
                        }}
                      >
                        <div className="d-flex flex-wrap gap-2 float-end ">
                          <Link
                            to="/business-point/list"
                            color="secondary"
                            className="btn btn-secondary"
                            role="button"
                            // onClick={this.handleCancel}
                            disabled={loading}
                          >
                            Cancel
                          </Link>{' '}
                          {isLoading ? (
                            <div className="text-center">
                              <Button color="primary" onClick={() => this.componentDidMount()}> Refresh </Button>
                            </div>
                          ) : guest == 'off' ? (
                            <Button
                              type="submit"
                              color="primary"
                              disabled={loading}
                            >
                              Simpan
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {/* <div className="d-flex flex-wrap gap-2 float-end mb-4">
                    <Button onClick={this.handleCancleClick} color="secondary">
                      Cancel
                    </Button>{' '}
                    <Button type="submit" color="primary" disabled={loading}>
                      Simpan
                    </Button>
                  </div> */}
                </Col>
              </AvForm>

              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h5">
                      Latest Changes Planogram by
                    </CardTitle>

                    <Row className="align-items-center">
                      <Col lg="9" className="align-self-center">
                        <div className="table-responsive">
                          <Table className="table align-middle table-nowrap table-hover mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Email</th>
                                <th scope="col">Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{user}</td>
                                <td>
                                  {timeStamp == null
                                    ? '-'
                                    : this.handledDate(timeStamp)}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>

                      <Col lg="3" className="d-none d-lg-block" align="center">
                        <div className="clearfix mt-4 mt-lg-0">
                          <ExcelFile
                            filename={filename}
                            element={
                              <button
                                type="button"
                                className="btn btn-primary w-sm"
                              >
                                <i className="mdi mdi-download d-block font-size-16"></i>
                                Download
                              </button>
                            }
                          >
                            <ExcelSheet data={history} name="Transaction List">
                              <ExcelColumn label="name" value="name" />
                              <ExcelColumn
                                label="pulse"
                                value="pulse"
                                numFmt="0"
                              />
                              <ExcelColumn label="duration" value="duration" />
                              <ExcelColumn
                                label="price"
                                value="price"
                                numFmt="0"
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
            <Modal
              key="modal"
              size="lg"
              isOpen={this.state.modalPlanogram}
              className={this.props.className}
              toggle={this.togglePlanogram}
              fullscreen
            >
              {/* <ModalHeader toggle={this.togglePlanogram} tag="h4"></ModalHeader> */}
              <ModalBody>
                <div className=" text-left">
                  {serverless_code == Result.NETWORK_FAILURE ? (
                    <FailedAnimation />
                  ) : serverless_code == Result.TIMEOUT ? (
                    <FailedAnimation />
                  ) : serverless_code == -11 ? (
                    <FailedAnimation />
                  ) : serverless_code == Result.FAILED ? (
                    <FailedAnimation />
                  ) : serverless_code == Result.INPUT_FAILURE ? (
                    <FailedAnimation />
                  ) : serverless_code == 0 ? (
                    <SuccessAnimation />
                  ) : (
                    <LoadingAnimation />
                  )}
                  {serverless_code == 20 ? (
                    <h3 className="text-center">
                      Sedang memproses data, mohon tunggu sebentar...
                    </h3>
                  ) : (
                    <div>
                      <Row>
                        <Col sm="4"></Col>
                        <Col sm="4">
                          <Card className="border-solid border-black">
                            <div className="table-responsive mb-2">
                              <Table className="border-solid border-2 table align-middle table-wrap">
                                <thead>
                                  <tr>
                                    <th scope="col" className="text-start">
                                      <div>
                                        <h5 className="font-size-14">
                                          Planogram ID:
                                        </h5>
                                      </div>
                                    </th>
                                    <th scope="row" className="text-end">
                                      <div>
                                        <h5 className="font-size-14">{ts}</h5>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th scope="col" className="text-start">
                                      <div>
                                        <h5 className="font-size-14">
                                          Update By:
                                        </h5>
                                      </div>
                                    </th>
                                    <th scope="row" className="text-end">
                                      <div>
                                        <h5 className="font-size-14">
                                          {localStorage.getItem('email')}
                                        </h5>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th scope="col" className="text-start">
                                      <div>
                                        <h5 className="font-size-14">
                                          Update Time:
                                        </h5>
                                      </div>
                                    </th>
                                    <th scope="row" className="text-end">
                                      <div>
                                        <h5 className="font-size-14">
                                          {this.handleValidDate(ts)}
                                        </h5>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th scope="col" className="text-start">
                                      <div>
                                        <h5 className="font-size-14">
                                          Status:
                                        </h5>
                                      </div>
                                    </th>
                                    <th scope="row" className="text-end">
                                      <div>
                                        {serverless_code ===
                                        Result.NETWORK_FAILURE ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, mesin sedang offline.
                                          </h5>
                                        ) : serverless_code ===
                                          Result.TIMEOUT ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, mesin tidak memberikan
                                            respon.
                                          </h5>
                                        ) : serverless_code === -11 ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, mesin sedang sibuk
                                            melakukan transaksi.
                                          </h5>
                                        ) : serverless_code ===
                                          Result.FAILED ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, mesin tidak merespons
                                            atau sedang offline.
                                          </h5>
                                        ) : serverless_code ===
                                          Result.INPUT_FAILURE ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, format planogram
                                            salah. Silakan hubungi Tim Iotera.
                                          </h5>
                                        ) : serverless_code ===
                                          Result.SUCCESS ? (
                                          <h5 className="font-size-14">
                                            Proses Selesai, mesin telah di
                                            update dengan planogram baru.
                                          </h5>
                                        ) : (
                                          <h5 className="font-size-14">
                                            Sedang memproses data, mohon tunggu
                                            sebentar...
                                          </h5>
                                        )}
                                      </div>
                                    </th>
                                  </tr>
                                  {serverless_code === Result.SUCCESS ? (
                                    <tr>
                                      <th scope="col" className="text-start">
                                        <div>
                                          <h5 className="font-size-14">
                                            Planogram data
                                          </h5>
                                        </div>
                                      </th>
                                      <th scope="row" className="text-end">
                                        <div className="clearfix mt-4 mt-lg-0">
                                          <ExcelFile
                                            filename={
                                              name +
                                              '_' +
                                              this.handleValidDate(ts)
                                            }
                                            element={
                                              <button
                                                type="button"
                                                className="btn btn-primary w-sm"
                                              >
                                                <i className="mdi mdi-download d-block font-size-16"></i>
                                                Download
                                              </button>
                                            }
                                          >
                                            {APPLICATION_ID == '1000000211' ||
                                            APPLICATION_ID == '1000000226' ? (
                                              <ExcelSheet
                                                data={planogram_history}
                                                name="Planogram History"
                                              >
                                                <ExcelColumn
                                                  label="selection"
                                                  value="selection"
                                                  numFmt="0"
                                                />
                                                <ExcelColumn
                                                  label="sku"
                                                  value="sku"
                                                />
                                                <ExcelColumn
                                                  label="name"
                                                  value="name"
                                                />
                                                <ExcelColumn
                                                  label="price"
                                                  value="price"
                                                  numFmt="0"
                                                />
                                              </ExcelSheet>
                                            ) : APPLICATION_ID ==
                                              '1000000258' ? (
                                              <ExcelSheet
                                                data={planogram_history}
                                                name="Planogram History"
                                              >
                                                <ExcelColumn
                                                  label="pulse"
                                                  value="pulse"
                                                  numFmt="0"
                                                />
                                                <ExcelColumn
                                                  label="duration"
                                                  value="duration"
                                                />
                                                <ExcelColumn
                                                  label="price"
                                                  value="price"
                                                  numFmt="0"
                                                />
                                              </ExcelSheet>
                                            ) : (
                                              <ExcelSheet
                                                data={
                                                  planogram_history
                                                }
                                                name="Planogram History"
                                              >
                                                <ExcelColumn
                                                  label="Selection"
                                                  value="key"
                                                />
                                                <ExcelColumn
                                                  label="Product"
                                                  value="name"
                                                />
                                                <ExcelColumn
                                                  label="Product SKU"
                                                  value="product"
                                                />
                                                <ExcelColumn
                                                  label="Product Price"
                                                  value="price"
                                                  numFmt="0"
                                                />
                                                <ExcelColumn
                                                  label="Stock"
                                                  value="stock"
                                                />
                                                <ExcelColumn
                                                  label="Active"
                                                  value="active"
                                                />
                                                <ExcelColumn
                                                  label="Column"
                                                  value="column"
                                                />
                                              </ExcelSheet>
                                            )}
                                          </ExcelFile>
                                        </div>
                                      </th>
                                    </tr>
                                  ) : (
                                    <></>
                                  )}
                                </thead>
                              </Table>
                            </div>
                          </Card>
                        </Col>
                        <Col sm="4"></Col>
                      </Row>
                    </div>
                  )}
                </div>
                <div className="text-center mt-4">
                  {serverless_code == Result.SUCCESS ? (
                    <Link
                      to={'/business-point/history/planogram'}
                      style={{
                        color: 'white',
                      }}
                      className="mb-2 btn btn-primary"
                    >
                      Next
                    </Link>
                  ) : serverless_code == 20 ? (
                    <></>
                  ) : (
                    <Button color="primary" onClick={this.togglePlanogram}>
                      Close
                    </Button>
                  )}
                </div>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  createResult: safeDeepGet(
    state,
    ['businessPoint', 'updatePlanogramWater'],
    {}
  ),
  readPlanogramResult: safeDeepGet(
    state,
    ['businessPoint', 'readPlanogramWater'],
    {}
  ),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  ReadPlanogram: id => dispatch(readPlanogramWater(id)),
  InputPlanogram: payload => dispatch(updatePlanogramWater(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramList);
