import {
    LOADING,
    RESULT,
    LIST_BUSINESS_POINTS,
    LIST,
    READ,
    REFUND,
    REFUNDED,
    UPDATE_REFUND,
    SUMMARY,
    SNAPSHOT,
    DETAIL,
    GET_SALES_ANALYTICS,
    REFUND_LIST,
    LIST_VM,
    DOWNLOAD
} from "./actionTypes";

export const refunded = payload => ({
    type: REFUNDED,
    payload,
  });

export const loading = (actionType) => ({
    type: LOADING,
    payload: { actionType },
});

export const result = (actionType, result) => ({
    type: RESULT,
    payload: { actionType, result },
});

export const list_business_points = () => ({
    type: LIST_BUSINESS_POINTS,
})
export const detail_transaction = payload => ({
    type:DETAIL,
    payload
})
export const list_vm = app_id => ({
    type:LIST_VM,
    app_id
})
export const download = payload => ({
    type:DOWNLOAD,
    payload
})
export const refund_list = payload => ({
    type: REFUND_LIST,
    payload
})
export const list = (payload) => ({
    type: LIST,
    dateRange: payload.dateRange,
    transactionStatus: payload.transactionStatus,
    dispenseStatus: payload.dispenseStatus,
    businessPoints: payload.businessPoints,
    bank: payload.bank
});

export const read = (transactionId) => ({
    type: READ,
    payload: { transactionId },
});

export const updateRefund = payload => ({
    type: UPDATE_REFUND,
    payload,
  });
  
export const refund = (transactionId) => ({
    type: REFUND,
    payload: { transactionId },
});

export const summary = (payload) => ({
    type: SUMMARY,
    businessPoints: payload.businessPoints,
    month: payload.month,
    year: payload.year,
    date: payload.dateRange,
    bank: payload.bank

});

export const snapshot = (payload) => ({
    type: SNAPSHOT,
    businessPoints: payload.businessPoints,
    month: payload.month,
    year: payload.year,
    date: payload.dateRange

});

export const get_sales_analytics = (payload) => ({
    type: GET_SALES_ANALYTICS,
    businessPoints: payload.businessPoints,
    month: payload.month,
    year: payload.year,
    date: payload.dateRange
});