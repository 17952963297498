/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
// Product state
import {
  LIST,
  LIST_BUSINESS_POINTS,
  READ,
  REFUND,
  SUMMARY,
  SNAPSHOT,
  GET_SALES_ANALYTICS,
  UPDATE_REFUND,
  REFUNDED,
  DETAIL,
  REFUND_LIST,
  LIST_VM,
  DOWNLOAD,
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
import { JGET, JPOST } from 'iotera-base/webservice/wsrequest';
import { getBp, getDevice } from '../../webservice/device';
import { APPLICATION_ID } from 'Apps';

function* detail_transaction(payload) {
  yield put(loading(DETAIL));
  const firebaseInstance = getInstance();
  const orderID = safeDeepGet(payload, 'payload', null);
  let wsResult = yield call(firebaseInstance.mapping.list);
  if (
    safeDeepGet(wsResult, 'code') == Result.SUCCESS ||
    safeDeepGet(wsResult, 'code') == Result.HTTP.OK
  ) {
    const mapBody = safeDeepGet(wsResult, 'body');

    const app_id = orderID.substring(6, 10);
    const url = safeDeepGet(mapBody, [app_id, 'url_business_point'], '');
    wsResult = yield call(getBp, url);
    if (
      safeDeepGet(wsResult, 'code') == Result.HTTP.OK ||
      safeDeepGet(wsResult, 'code') == Result.SUCCESS
    ) {
      const businessPointBody = wsResult.body;
      const businessPointMap = {};
      for (const id in businessPointBody) {
        const deviceId = safeDeepGet(businessPointBody[id], 'device_id', '');
        businessPointMap[deviceId] = businessPointBody[id];
      }
      wsResult = yield call(firebaseInstance.trxAdmin.detail, orderID);
      if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
        const transactionMap = wsResult.body;
        const transactionList = [];
        let i = 0;

        for (const orderId in transactionMap) {
          safeDeepSet(transactionMap, [orderId, 'id'], i++);
          let device_id = safeDeepGet(transactionMap[orderId], [
            'product',
            'device_id',
          ]);
          let bp_name = safeDeepGet(businessPointMap, [device_id, 'name'], '-');
          safeDeepSet(transactionMap, [orderId, 'name'], bp_name);
          let badgeclass = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'settlement'
          ) {
            badgeclass = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'refund_pending'
          ) {
            badgeclass = 'warning';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'cancel'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'timeout'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'failure'
          ) {
            badgeclass = 'danger';
          }

          let badge = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_timeout'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_column_unresponsive'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense_vm_error'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'success'
          ) {
            badge = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'pending'
          ) {
            badge = 'warning';
          }
          const data = safeDeepGet(
            transactionMap[orderId],
            ['product', 'detail'],
            safeDeepGet(transactionMap[orderId], ['product'], {})
          );
          const dataBp = [];
          for (const i in data) {
            const device_id = safeDeepGet(
              data[i],
              'business_point',
              safeDeepGet(data[i], 'id', '-')
            );
            let bp_name = safeDeepGet(
              businessPointMap,
              [device_id, 'name'],
              safeDeepGet(data[i], 'name', '-')
            );
            safeDeepSet(data[i], 'business_point', bp_name);
            dataBp.push(data[i]);
          }
          safeDeepSet(transactionMap, [orderId, 'bp'], dataBp);
          safeDeepSet(transactionMap, [orderId, 'badge'], badge);
          safeDeepSet(transactionMap, [orderId, 'badgeclass'], badgeclass);
          // FILTERING
          transactionList.push(transactionMap[orderId]);
        }
        yield put(
          result(DETAIL, { code: Result.SUCCESS, body: transactionList })
        );
        return;
      }
      yield put(result(DETAIL, { result: { code: Result.FAILED } }));
    }
    yield put(result(DETAIL, { result: { code: Result.FAILED } }));
  }
}
function* list_business_points() {
  yield put(loading(LIST_BUSINESS_POINTS));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.mapping.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    const bpFilterList = [{ label: 'All points', value: 'all' }];
    for (const deviceId in businessPointMap) {
      let bp_name = safeDeepGet(businessPointMap, [deviceId, 'name']);
      const app_id = '100000' + deviceId;
      bpFilterList.push({ label: bp_name, value: app_id });
    }
    yield put(
      result(LIST_BUSINESS_POINTS, { code: Result.SUCCESS, body: bpFilterList })
    );
    return;
  }
  yield put(result(LIST_BUSINESS_POINTS, { result: { code: Result.FAILED } }));
}

function* list_vm(payload) {
  yield put(loading(LIST_VM));
  const appId = safeDeepGet(payload, 'app_id', '');

  let wsResult = yield call(getDevice, appId);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.HTTP.OK) {
    const devices = safeDeepGet(wsResult, ['body', 'devices'], []);
    const deviceList = [];
    deviceList.push({ label: 'All Point', value: 'all' });
    for (const i in devices) {
      const deviceName = safeDeepGet(devices, [i, 'label'], '');
      deviceList.push({ label: deviceName, value: i });
    }
    yield put(result(LIST_VM, { code: Result.SUCCESS, body: deviceList }));
    return;
  }
  yield put(result(LIST_VM, { result: { code: Result.FAILED } }));
}

function* list(payload) {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    wsResult = yield call(firebaseInstance.product.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const productMap = wsResult.body;
      wsResult = yield call(firebaseInstance.trxAdmin.list, payload);
      if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
        const transactionMap = wsResult.body;
        const transactionList = [];
        let i = 0;
        for (const orderId in transactionMap) {
          safeDeepSet(transactionMap, [orderId, 'id'], i++);
          let productSku = safeDeepGet(transactionMap[orderId], [
            'product',
            'sku',
          ]);
          let productName = safeDeepGet(productMap, [productSku, 'name']);
          safeDeepSet(transactionMap, [orderId, 'nameProduct'], productName);
          let device_id = safeDeepGet(transactionMap[orderId], [
            'product',
            'device_id',
          ]);
          let bp_name = safeDeepGet(businessPointMap, [device_id, 'name'], '-');
          safeDeepSet(transactionMap, [orderId, 'name'], bp_name);
          let badgeclass = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'settlement'
          ) {
            badgeclass = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'credit'
          ) {
            badgeclass = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'refund_pending'
          ) {
            badgeclass = 'warning';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'cancel'
          ) {
            badgeclass = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'transaction_status',
            ]) == 'failure'
          ) {
            badgeclass = 'danger';
          }

          let badge = 'info';
          if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'no_dispense'
          ) {
            badge = 'danger';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'success'
          ) {
            badge = 'success';
          } else if (
            safeDeepGet(transactionMap[orderId], [
              'detail',
              'dispense_status',
            ]) == 'pending'
          ) {
            badge = 'warning';
          }

          safeDeepSet(transactionMap, [orderId, 'badge'], badge);
          safeDeepSet(transactionMap, [orderId, 'badgeclass'], badgeclass);

          // FILTERING
          transactionList.push(transactionMap[orderId]);
        }

        yield put(
          result(LIST, { code: Result.SUCCESS, body: transactionList })
        );
        return;
      }
      yield put(result(LIST, { result: { code: Result.FAILED } }));
    }
    yield put(result(LIST, { result: { code: Result.FAILED } }));
  }
}

function* read(payload) {}

function* refund(payload) {}

function* summary(payload) {
  yield put(loading(SUMMARY));
  const firebaseInstance = getInstance();
  let wsResult = yield call(
    firebaseInstance.transaction.nett_settlement,
    payload
  );
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const summaryCurrentMap = wsResult.body;
    var payload_prev = payload;
    safeDeepSet(payload_prev, ['month'], safeDeepGet(payload, ['month']) - 1);
    wsResult = yield call(
      firebaseInstance.transaction.nett_settlement,
      payload_prev
    );

    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const summaryPrevMap = wsResult.body;
      wsResult = yield call(firebaseInstance.businessPoint.list);

      if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
        const bpInfo = wsResult.body;
        let landlord_current = sum_month_share(summaryCurrentMap, bpInfo);
        let landlord_prev = sum_month_share(summaryPrevMap, bpInfo);
        let landlord_change = calc_change(landlord_current, landlord_prev);

        let trx_current = sum_month(summaryCurrentMap);
        let trx_prev = sum_month(summaryPrevMap);
        let trx_change = calc_change(trx_current, trx_prev);

        let refund_current = sum_month_refund(summaryCurrentMap);
        let refund_prev = sum_month_refund(summaryPrevMap);
        let refund_change = calc_change(refund_current, refund_prev);

        let settlement_current = sum_settlement(summaryCurrentMap);
        let settlement_prev = sum_settlement(summaryPrevMap);
        let settlement_change = calc_change(
          settlement_current,
          settlement_prev
        );

        const ret_summary = {
          landlord_current: landlord_current,
          landlord_prev: landlord_prev,
          landlord_change: landlord_change,
          trx_current: trx_current,
          trx_prev: trx_prev,
          trx_change: trx_change,
          refund_current: refund_current,
          refund_prev: refund_prev,
          refund_change: refund_change,
          settlement_current: settlement_current,
          settlement_prev: settlement_prev,
          settlement_change: settlement_change,
        };
        yield put(result(SUMMARY, { code: Result.SUCCESS, body: ret_summary }));
        return;
      }
    }
  }
  yield put(result(SUMMARY, { result: { code: Result.FAILED } }));
}

function* snapshot(payload) {
  yield put(loading(SNAPSHOT));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);

  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    wsResult = yield call(firebaseInstance.snapshot.list, payload);

    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const snapshotMap = wsResult.body;
      const snapshotList = [];
      let i = 0;

      for (const orderId in snapshotMap) {
        safeDeepSet(snapshotMap, [orderId, 'id'], i++);
        let device_id = safeDeepGet(snapshotMap[orderId], [
          'product',
          'device_id',
        ]);
        let bp_name = safeDeepGet(businessPointMap, [device_id, 'name'], '-');

        safeDeepSet(snapshotMap, [orderId, 'name'], bp_name);
        let dispenseStatus = 'no dispense';
        if (
          safeDeepGet(snapshotMap[orderId], [
            'transactionDetails',
            'isDispense',
          ])
        ) {
          dispenseStatus = 'success';
        }
        safeDeepSet(snapshotMap, [orderId, 'dispensestatus'], dispenseStatus);
        // FILTERING
        snapshotList.push(snapshotMap[orderId]);
      }
      yield put(result(SNAPSHOT, { code: Result.SUCCESS, body: snapshotList }));
      return;
    }
    yield put(result(SNAPSHOT, { result: { code: Result.FAILED } }));
  }
}

function* get_sales_analytics(payload) {
  yield put(loading(GET_SALES_ANALYTICS));
  const firebaseInstance = getInstance();
  let wsResult = yield call(
    firebaseInstance.transaction.nett_settlement,
    payload
  );

  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const nsMap = wsResult.body;
    wsResult = yield call(firebaseInstance.product.list);
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      const productMap = wsResult.body;
      var holder = {};
      for (var d in nsMap) {
        var d_val = safeDeepGet(nsMap, [d]);
        let productSku = safeDeepGet(d_val, ['product', 'sku']);
        let productName = safeDeepGet(productMap, [productSku, 'name']);
        safeDeepSet(d_val, ['nameProduct'], productName);
        if (
          safeDeepGet(d_val, ['detail', 'transaction_status']) == 'settlement'
        ) {
          if (holder.hasOwnProperty(d_val.nameProduct)) {
            holder[d_val.nameProduct] = holder[d_val.nameProduct] + 1;
          } else {
            holder[d_val.nameProduct] = 1;
          }
        }
      }

      var ret_sales_analytics = [];
      for (var prop in holder) {
        ret_sales_analytics.push({ name: prop, value: holder[prop] });
      }

      yield put(
        result(GET_SALES_ANALYTICS, {
          code: Result.SUCCESS,
          body: ret_sales_analytics,
        })
      );
      return;
    }
  }
  yield put(result(GET_SALES_ANALYTICS, { result: { code: Result.FAILED } }));
}

function calc_change(sum_current, sum_prev) {
  var temp_change = 0.0;
  if (sum_prev == 0 && sum_current > 0) {
    temp_change = 100.0;
  } else if (sum_prev == 0 && sum_current == 0) {
    temp_change = 0.0;
  } else {
    temp_change = (sum_current / sum_prev - 1.0) * 100.0;
  }
  return temp_change;
}

function sum_month_share(nsMap, bpInfo) {
  var resultTotal = 0;
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);

    var device_id = safeDeepGet(val, ['product', 'device_id']);
    const share = safeDeepGet(bpInfo, [device_id, 'landlord_share']);
    if (safeDeepGet(val, ['detail', 'transaction_status']) == 'settlement') {
      resultTotal += val.payment.amount * share;
    }
  }
  return resultTotal;
}

function sum_month_refund(nsMap) {
  var resultTotal = 0;
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    if (
      safeDeepGet(val, ['detail', 'transaction_status']) == 'refund' ||
      safeDeepGet(val, ['detail', 'transaction_status']) == 'refunded'
    ) {
      resultTotal += val.payment.amount;
    }
  }
  return resultTotal;
}

function sum_month(nsMap) {
  var resultTotal = 0;
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    if (safeDeepGet(val, ['detail', 'transaction_status']) == 'settlement') {
      resultTotal += val.payment.amount;
    }
  }
  return resultTotal;
}
function sum_settlement(nsMap) {
  var resultTotal = 0;
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns]);
    if (safeDeepGet(val, ['detail', 'transaction_status']) == 'settlement') {
      resultTotal += 1;
    }
  }
  return resultTotal;
}

function* updateRefund(action) {
  yield put(loading(UPDATE_REFUND));
  const id = safeDeepGet(action, ['payload', 'order_id']);
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();
  const wsResult = yield call(
    firebaseInstance.transaction.updateRefund,
    id,
    payload
  );
  const code = safeDeepGet(wsResult, 'code');
  yield put(result(UPDATE_REFUND, { code }));
}

function Refund(action, token_id) {
  const order_id = safeDeepGet(action, ['payload', 'id']);
  const device_id = safeDeepGet(action, ['payload', 'device_id']);
  const amount = safeDeepGet(action, ['payload', 'amount']);
  let tokenId = token_id;
  return JPOST({
    url: 'https://pay.iotera.io/transaction/refund',
    headers: {
      'Iotera-Payment-Application-Id': APPLICATION_ID,
      'Iotera-Payment-Device-Id': device_id,
      'Iotera-Payment-Device-Token': tokenId,
    },
    body: {
      transaction_id: order_id,
      amount: amount,
    },
  })
    .then(function (response) {
      return response;
    })
    .then(data => data);
}

function GetDevice() {
  var result = JGET({
    url: 'https://pay.iotera.io/web/device/list',
    headers: {
      'Iotera-Payment-Application-Id': APPLICATION_ID,
      'Iotera-Payment-Admin-Token':
        '6bf868df24a4357dee20e6d3d6ef1d1944249ad44cb29687446d346f60fc0215',
    },
  })
    .then(function (response) {
      return response;
    })
    .then(data => data);

  return result;
}

function* refunded(action) {
  yield put(loading(REFUNDED));
  let wsResult = yield call(GetDevice);

  if (safeDeepGet(wsResult, 'code') == 200) {
    const deviceMap = wsResult.body.data;
    const deviceList = [];
    let i = 0;
    for (const device in deviceMap) {
      safeDeepSet(deviceMap, [device, 'id'], i++);
      deviceList.push(deviceMap[device]);
    }
    const deviceid = safeDeepGet(action, ['payload', 'device_id']);
    const data = deviceList.find(({ device_id }) => device_id === deviceid);
    const token_id = data.token;
    let Result = yield call(Refund, action, token_id);
    const code = safeDeepGet(Result, 'code');
    yield put(result(REFUNDED, { code }));
    if (code == 200 || code == 0) {
      const firebaseInstance = getInstance();
      const order_id = safeDeepGet(action, ['payload', 'id']);
      yield call(firebaseInstance.chat.update, order_id);
      yield call(firebaseInstance.refund.create, action);
    }
  }
}
function* refund_list(payload) {
  yield put(loading(REFUND_LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.refund.list, payload);
  const code = safeDeepGet(wsResult, 'code');
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const refundBody = safeDeepGet(wsResult, 'body');
    const refundList = [];
    wsResult = yield call(firebaseInstance.businessPoint.list);
    const businessPointMap = wsResult.body;

    for (const i in refundBody) {
      const device_id = safeDeepGet(refundBody, [i, 'device_id'], '-');
      let bp_name = safeDeepGet(businessPointMap, [device_id, 'name']);
      safeDeepSet(refundBody, [i, 'nameBp'], bp_name);
      refundList.push(refundBody[i]);
    }
    yield put(result(REFUND_LIST, { code: Result.SUCCESS, body: refundList }));
    return;
  }
  yield put(result(REFUND_LIST, { code: Result.FAILED, body: [] }));
}
function formatToJakartaTime(utcDateString) {
  // Create a Date object from the UTC string
  const date = new Date(utcDateString);

  // Convert to Jakarta time (UTC+7)
  const jakartaOffset = 7 * 60; // Jakarta is UTC+7
  const jakartaTime = new Date(date.getTime() + jakartaOffset * 60 * 1000);

  // Format the date to 'YYYY-MM-DD_HH-MM-SS'
  const year = jakartaTime.getUTCFullYear();
  const month = String(jakartaTime.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(jakartaTime.getUTCDate()).padStart(2, '0');


  return `${year}-${month}-${day}`;
}

function* download(payload) {
  yield put(loading(DOWNLOAD));
  const firebaseInstance = getInstance();
  const data = safeDeepGet(payload, 'payload', {});
  let wsResult = yield call(firebaseInstance.mapping.list);
  if (
    safeDeepGet(wsResult, 'code') == Result.SUCCESS ||
    safeDeepGet(wsResult, 'code') == Result.HTTP.OK
  ) {
    const mapBody = safeDeepGet(wsResult, 'body');
    const dateRange = safeDeepGet(data, 'dateRange', [])
    const application_id = safeDeepGet(data, 'application_id', '');

    const app_id = application_id.substring(
      application_id.length - 4,
      application_id.length
    );
    const multitenancy = safeDeepGet(mapBody, [app_id, 'multitenancy'], '');
    const name = safeDeepGet(mapBody, [app_id, 'name'], '');
    data['multitenancy'] = multitenancy;
    data['fromAdmin'] = true;
    try {
      const url = safeDeepGet(mapBody, [app_id, 'url_download'], '');
      const fileName = name + " " + formatToJakartaTime(dateRange[0]) +  " - " + formatToJakartaTime(dateRange[1])
      const response = yield call(() =>
        axios.post(url, data, {
          responseType: 'blob',
        })
      );

      const buffer = response.data;
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const downloadLink = document.createElement('a');
      const urlw = window.URL.createObjectURL(blob);
      downloadLink.href = urlw;
      downloadLink.download = fileName + '.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);

      yield put(result(DOWNLOAD, { code: Result.SUCCESS }));
    } catch (error) {
      yield put(result(DOWNLOAD, { code: Result.SUCCESS, error: error }));
    }
  }
}
function* transactionSaga() {
  yield takeEvery(LIST_BUSINESS_POINTS, list_business_points);
  yield takeEvery(LIST, list);
  yield takeEvery(LIST_VM, list_vm);
  yield takeEvery(READ, read);
  yield takeEvery(REFUND, refund);
  yield takeEvery(UPDATE_REFUND, updateRefund);
  yield takeEvery(SUMMARY, summary);
  yield takeEvery(SNAPSHOT, snapshot);
  yield takeEvery(GET_SALES_ANALYTICS, get_sales_analytics);
  yield takeEvery(REFUNDED, refunded);
  yield takeEvery(DETAIL, detail_transaction);
  yield takeEvery(REFUND_LIST, refund_list);
  yield takeEvery(DOWNLOAD, download);
}

export default transactionSaga;
