import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import dashboardSaga from "./dashboard/saga"
import businessPointSaga from './business-point/saga'
import productSaga from './product/saga'
import transactionSaga from './transaction/saga'
import refillerSaga from './refiller/saga'
import couponSaga from './coupon/saga'
import alertSaga from './alert/saga'
import projectConfigSaga from './projectConfig/saga'
import payoutSaga from './payout/saga'
import chatSaga from './chat/saga'
import discountSaga from './discount/saga'
import RoleSaga from './Role/saga'
import accountRoleSaga from './account/saga'
import errorExSaga from './errorEx/saga'
import subsSaga from './subs/saga'
import invoiceSaga from './invoice/saga'
import categorySaga from './category/saga'
import rfidSaga from './rfid/saga'
import tagsSaga from './tag/saga'
import ingredientSaga from './ingredient/saga'

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(RoleSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(businessPointSaga),
    fork(productSaga),
    fork(transactionSaga),
    fork(chatSaga),
    fork(payoutSaga),
    fork(discountSaga),
    fork(couponSaga),
    fork(alertSaga),
    fork(refillerSaga),
    fork(accountRoleSaga),
    fork(errorExSaga),
    fork(subsSaga),
    fork(invoiceSaga),
    fork(categorySaga),
    fork(projectConfigSaga),
    fork(ingredientSaga),
    fork(rfidSaga),
    fork(tagsSaga)
    
  ])
}
